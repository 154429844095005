import React, {
  useState,
} from 'react';
import { faPencilAlt, faSave }     from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SubscriptionDetails = ({
  subscription,
  updateSubscriptionFlags,
  updateSubscriptionMinQuantities,
  prices,
  close,
  updateClientMemo,
}) => {
  const { plans } = subscription;
  const [ editing_memo, setEditingMemo ] = useState();
  const [ memo, setMemo ] = useState(subscription.client.memo);
  const [ min_quantities, setMinQuantities ] = useState(subscription.min_quantities ?? {});
  const [ flags, setFlags ] = useState(subscription.flags ?? {});
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        gap: '0.5em',
      }}>
        <b>Nombre del plan</b>
        <b>Cantidad</b>
        {plans.map((plan, i) => (
          <div style={{ display: 'contents' }} key={i}>
            <div>{plan.name}</div>
            <div>{plan.quantity ?? plan.usage}</div>
          </div>
        ))}
      </div>
      <div>
        <b>Comentarios facturas:</b>
        <div style={{display: 'flex', gap: '1em', alignItems: 'center'}}>
          {editing_memo ? (
            <>
              <input type="text" value={memo} onChange={e => setMemo(e.target.value)}/>
              <FontAwesomeIcon onClick={async () => {
                await updateClientMemo(subscription.client_id, memo);
                setEditingMemo(false)
              }} icon={faSave}/>
            </>
          ) : (
            <>
              <div>{memo}</div>
              <FontAwesomeIcon onClick={() => setEditingMemo(true)} icon={faPencilAlt}/>
            </>
          )}
        </div>
      </div>
      <div>
        <b>Crédito restante:</b>
        <div>{Number(subscription.remaining_credit || 0).toLocaleString('es-ES', { style: 'currency', currency: subscription.currency })}
        </div>
      </div>
      <div>
        <b>Cantidades mínimas de planes</b>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 3em',
          gap: '1em',
          alignItems: 'center',
        }}>
        {Object.keys(prices).map(price => (
          <>
            {price}
            <input
              type="number"
              value={min_quantities[price]}
              onChange={e => (v => setMinQuantities(p => ({...p, [price]: v})))(e.target.value)}
            />
          </>
        ))}
        </div>
      </div>
      <div>
        <b>Configuración avanzada:</b>
        <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center', gap: '0.5em'}}>
          <input
            type="checkbox"
            checked={flags.prorate_changes}
            onClick={e => (c => setFlags(f => ({ ...f, prorate_changes: c })))(e.target.checked)}
          />
          <div>Proraterar cambios de licencias</div>
          <input
            type="checkbox"
            checked={flags.uses_bank_transfer}
            onClick={e => (c => setFlags(f => ({ ...f, uses_bank_transfer: c })))(e.target.checked)}
          />
          <div>Cobrar por transferencia bancaria (BETA)</div>
        </div>
      </div>
      <div>
        <button onClick={() => {
          Promise.all([
            updateSubscriptionFlags(subscription.client_id, subscription._id, flags),
            updateSubscriptionMinQuantities(subscription.client_id, subscription._id, min_quantities),
          ]).then(close);
        }}>
          Guardar cambios
        </button>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
