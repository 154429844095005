import React, {
  useState,
}             from 'react';
import moment from 'moment';

moment.locale('es');

const EmailSync = ({
  dealer_id,
  user,
}) => {
  const [ sync_response, setSyncResponse ] = useState('');
  const [ loading, setLoading ] = useState('');

  const full_name = user && `${user?.first_name} ${user?.last_name}`
  const last_successful_renovation = user?.email_settings?.incoming?.last_successful_renovation;
  const sync_status = user?.email_settings?.incoming?.sync_status;

  const forceSync = (dealer_id, user_id, since_date) => fetch(`/api/mappex/services/mail/incoming/dealers/${dealer_id}/syncUser/${user_id}`, {
    method:'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      full_sync: true,
      full_sync_since: since_date,
      partial_sync: true,
      ignore_tag: true,
    }),
  }).then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
    .then(() => {setLoading(false); setSyncResponse('Petición realizada correctamente. El proceso de sincronización puede tardar hasta 20 min.');})
    .catch(err => {setLoading(false); setSyncResponse(`Error: ${err}`);})

  return (
    <div>
      <div>Permite forzar la sincronización de correo de un usuario desde la fecha de última sincronización.</div>
      {dealer_id && user ? (
        <div style={{display: 'flex', alignItems: 'center', gap: '3em'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {[
              ['Nombre', full_name],
              ['E-mail', user?.email],
              ['Última sincronizacióin', last_successful_renovation && moment(last_successful_renovation).format('LLL')],
              ['Sync status', sync_status],
            ].map(([k, v], i) => (
              <div key={i} style={{display: 'flex', gap: '0.5em'}}>
                <span>{k}</span>
                <span style={{fontWeight: 'bold'}}>{v}</span>
              </div>
            ))}
          </div>
          <button
            disabled={!last_successful_renovation || loading}
            onClick={() =>{
              setLoading(true);
              setSyncResponse('');
              forceSync(dealer_id, user?._id, last_successful_renovation)
            }}
          >
            Forzar sincro
          </button>
          <div>{sync_response}</div>
        </div>
      ) : (
        <div style={{color: 'red'}}>ERROR: component requires a dealer_id and user to work</div>
      )}
    </div>
  );
};

export default EmailSync;
