import React, {
  useState,
  useEffect,
  useLayoutEffect,
}                    from 'react';
import ReactDOM      from 'react-dom';
import { usePopper } from 'react-popper';
import styled        from 'styled-components';

const StyledTooltip = styled('div')`
  background: var(--background-light-1);
  border-radius: 4px;
  box-sizing: border-box;
  border: 0px;
  box-shadow: 0 4px 14px 0 rgba(var(--background-dark-2-rgb),.2),0 0 0 1px rgba(var(--background-dark-2-rgb),.05);
  font-size: var(--fonts-default-size-small);
  color: var(--content-4);
  max-height: 300px;
  max-width: 300px;
  overflow: auto;
  padding: 0.5em;
  white-space: pre-wrap;
`;

const Tooltip = ({
  tag_component,
  component_style,
  content,
  children,
  disabled,
  delay=0,
  placement,
  tooltip_style={},
  portal_element,
}) => {
  const TagComponent = tag_component || 'div';
  // If we use useRef instead of useState the Tooltip wont be positioned correctly
  const [parent_ref, setParentRef] = useState(null);
  const [tooltip_ref, setTooltipRef] = useState(null);
  const [ hovered, setHovered ] = useState(false);
  const [ visible, setVisible ] = useState(false);
  const { styles, update } = usePopper(parent_ref, tooltip_ref, {
    modifiers: [
      {name: 'eventListeners', options: {scroll: visible, resize: false}},
    ],
    strategy: 'absolute',
    placement: placement || 'bottom',
  });

  useEffect(() => {
    if (hovered) {
      const timer = delay ? setTimeout(() => setVisible(true), delay) : setVisible(true);
      return () => timer && clearTimeout(timer);
    } else {
      setVisible(false)
    }
  }, [hovered, delay]);

  useLayoutEffect(() => {
    // Needed because Popper won't display the popup in the correct position otherwise,
    // until you scroll/move around. This is because the element being drawn (display to true)
    // doesn't fire popper functions by default
    if (update && visible && !disabled) update();
  }, [update, visible, disabled]);

  const tooltip_content = (
    <>
      {visible && !disabled && content ? (
        <StyledTooltip
          className='fancy-scrollbar'
          ref={setTooltipRef}
          style={{
            zIndex: 5,
            ...styles.popper,
            ...tooltip_style,
          }}
          onClick={e => e.stopPropagation()}
        >
          {content}
        </StyledTooltip>
      ) : (
        <></>
      )}
    </>
  );

  return (
   <TagComponent
      ref={setParentRef}
      style={component_style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
      {portal_element ? ReactDOM.createPortal(tooltip_content, portal_element) : tooltip_content}
    </TagComponent>
  );
};

export default Tooltip;
