import React, {
  useState,
} from 'react';
import AsyncButton from '../../../Billing/components/AsyncButton';

const PhoneProvision = ({ dealer_id }) => {
  const [ number, setNumber ] = useState();
  const provisionNumber = () => fetch(`/api/provision_phone`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ dealer_id, number }),
  })
    .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
    .then(() => alert('Número provisionado con éxito.'))
    .catch(err => alert('Error provisionando el número: '.concat(err.toString())))
  return (
    <div style={{
      display: 'grid',
      gridGap: '0.5em 1em',
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
    }}>
      <span>Número:</span>
      <input value={number} onChange={e => setNumber(e.target.value)}/>
      <AsyncButton
        onClick={provisionNumber}
        disabled={!number}
      >
        Provisionar
      </AsyncButton>
    </div>
  );
};

export default PhoneProvision;
