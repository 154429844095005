import React, {
  useState,
}                   from 'react';
import moment       from 'moment';
import Select       from 'react-select';
import StyledButton from '../StyledButton';

moment.locale('es');

const processFetchResponse = u => u.ok ? u.json() : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`));

const select_options = [
  { value: 'percentage', label: 'Porcentaje' },
  { value: 'fixed_amount', label: 'Cantidad fija' },
];

const duration_options = [
  { value: 'repeating', label: 'Duración limitada' },
  { value: 'forever', label: 'Permanente' },
];

const input_style = {
  borderRadius: '3px',
  outline: 'none',
  border: '1px solid lightgrey',
  padding: '0.3em 0.6em',
};

const DiscountEditor = ({
  subscription,
  setSubscriptions,
}) => {
  const [discount_type, setDiscountType] = useState('percentage');
  const [fixed_amount, setFixedAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [duration, setDuration] = useState('repeating');
  const [duration_months, setDurationMonths] = useState(1);
  const deleteDiscount = () => fetch(`/api/billing/subscriptions/${subscription._id}/discount`, {
    method: 'DELETE',
  })
    .then(processFetchResponse)
    .then(s => setSubscriptions(subs => ({ ...subs, [s._id]: s })))
  const setDiscount = () => fetch(`/api/billing/subscriptions/${subscription._id}/discount`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      discount_type,
      percentage,
      fixed_amount,
      duration,
      duration_months,
    }),
  })
    .then(processFetchResponse)
    .then(s => setSubscriptions(subs => ({ ...subs, [s._id]: s })))
  return subscription.discount ? (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
      Descuento aplicado
      <span>- Duración: {subscription.discount?.duration === 'repeating' ? subscription.discount?.duration_months + ' meses' : 'Indefinida'}</span>
      <span>- Fecha de aplicación: {subscription.discount_applied_at && moment(subscription.discount_applied_at).format('dddd, DD [de] MMMM [de] YYYY, HH:mm:ss') || 'Pendiente de aplicación'}</span>
      <StyledButton onClick={deleteDiscount}>
        Quitar descuento
      </StyledButton>
    </div>
  ) : (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
      <div style={{ display: 'grid', gap: '0.5em', alignItems: 'center', gridTemplateColumns: 'auto 1fr' }}>
        Tipo descuento:
        <Select
          options={select_options}
          value={select_options.find(o => o.value === discount_type)}
          onChange={o => setDiscountType(o.value)}
        />
        {discount_type === 'percentage' && (
          <>
            Porcentaje descuento:
          <input style={input_style} type='number' min="1" max="100" value={percentage} onChange={e => setPercentage(e.target.value)} />
          </>
        )}
        {discount_type === 'fixed_amount' && (
          <>
            Cantidad a descontar:
          <input style={input_style} type='number' value={fixed_amount/100} onChange={e => setFixedAmount(e.target.value*100)} />
          </>
        )}
      Duración:
      <Select
          options={duration_options}
          value={duration_options.find(o => o.value === duration)}
          onChange={o => setDuration(o.value)}
        />
        {duration === 'repeating' && <>
          Duración en meses:
        <input style={input_style} type='number' value={duration_months} onChange={e => setDurationMonths(e.target.value)} />
        </>}
      </div>
      <StyledButton onClick={setDiscount}>
        Guardar
      </StyledButton>
    </div>
  );
};

export default DiscountEditor;
