import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  forwardRef,
}                       from 'react';
import ReactJson        from 'react-json-view';
import Toggle           from 'react-toggle';
import Select           from 'react-select';
import styled           from 'styled-components';
import DatePicker       from 'react-datepicker';
import Modal            from '../Admin/components/Modal';

import "react-datepicker/dist/react-datepicker.css";

const AuditEventEntry = styled.div`
  &:hover {
    background: #555555;
  }
`;

const AuditEventDetails = ({audit_event_details}) =>
  (<ReactJson
    src={audit_event_details}
    name={false}
    enableClipboard={false}
    displayDataTypes={false}
    displayObjectSize={false}
    displayArrayKey={false}
  />);

const AuditEventDetailsModal = Modal(AuditEventDetails);

const limit = 50;

const loadAuditEventTypes = () =>
  fetch(`/api/audit_event_types`)
    .then(r => r.ok ?
      r.json() :
      r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`))
    );

const loadAuditEvents = (skip, start, end, filter_text, auditevent_types) =>
  fetch(`/api/audit_events?${new URLSearchParams(
    Object.fromEntries(
      Object.entries({limit, skip, start, end, filter_text, auditevent_types})
      .filter(entry => entry[1] !== undefined)
    )
  ).toString()}`)
    .then(r => r.ok ?
      r.json() :
      r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`))
    );

const AuditEvents = () => {
  document.title = 'Audit events - Walcu Penguin';

  const [ skip, setSkip ] = useState(limit);
  const [ audit_events, setAuditEvents ] = useState([]);
  const [ focused_audit_event, setFocusedAuditEvent ] = useState(null);
  const [ no_more_found, setNoMoreFound ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ load_on_scroll, setLoadOnScroll ] = useState(true);

  const [ start_date, setStartDate ] = useState();
  const search_start_date = useMemo(() => {
    if (!start_date) return;
    return new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate()).toISOString();
  }, [start_date]);
  const [ end_date, setEndDate ] = useState(null);
  const search_end_date = useMemo(() => {
    if (!end_date) return;
    return new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate() + 1).toISOString();
  }, [end_date]);

  const [ auditevent_type, setAuditEventType ] = useState();
  const search_auditevent_types = useMemo(() => {
    return auditevent_type?.map(aet => aet.value);
  }, [auditevent_type]);
  const [ select_options, setSelectOptions ] = useState();

  const [ filter_text, setFilterText ] = useState('');
  const [ search_filter_text, setSearchFilterText ] = useState(filter_text);
  const filter_reg_exp = useMemo(() => {
    try {
      return new RegExp(filter_text);
    } catch (err) {/**/}
  }, [filter_text]);

  const loadMore = useCallback(async () => {
    setLoading(true);
    const next_audit_events = await loadAuditEvents(skip, search_start_date, search_end_date, search_filter_text, search_auditevent_types);
    setAuditEvents(aes => aes.concat(next_audit_events));
    if (next_audit_events.length < limit) setNoMoreFound(true);
    setSkip(sk => sk + limit);
    setLoading(false);
  }, [search_start_date, search_end_date, skip, search_filter_text, search_auditevent_types]);

  const resetSearch = useCallback(() => {
    setSkip(limit);
    setAuditEvents([]);
    setNoMoreFound(false);
    setLoading(true);
    loadAuditEvents(0, search_start_date, search_end_date, search_filter_text, search_auditevent_types)
      .then(aes => {
        setAuditEvents(aes);
        setLoading(false);
      });
  }, [search_start_date, search_end_date, search_filter_text, search_auditevent_types]);

  useEffect(() => {
    resetSearch();
  }, [resetSearch]);

  useEffect(() => {
    loadAuditEventTypes()
      .then(types => setSelectOptions(types.map(t => ({value: t, label: t}))));
  }, []);

  const scrollRef = useRef();

  const scrollCheck = useCallback(() => {
    const t = scrollRef.current;
    if (!load_on_scroll || no_more_found || loading) return;
    if (t.scrollHeight + t.scrollTop === t.offsetHeight) loadMore();
  }, [load_on_scroll, no_more_found, loading, loadMore]);

  useEffect(() => {
    if (audit_events?.length) scrollCheck()
  }, [audit_events, scrollCheck]);

  const DateCustomInput = forwardRef((...args) => {
    const props = args[0];
    return (<button
      onClick={props.onClick}
      style={{
        padding: '0.4em 2.2em',
        border: '0px solid #AAAAAA',
        borderRadius: '0.2em',
      }}
    >
      {props.value || <span style={{color: 'gray'}}>{props.placeholder}</span>}
    </button>);
  });

  return (<>
    <AuditEventDetailsModal
      title={'Audit event details'}
      opened={Boolean(focused_audit_event)}
      close={() => setFocusedAuditEvent(null)}
      audit_event_details={focused_audit_event}
    />
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        backgroundColor: '#BBBBBB',
      }}
      onScroll={scrollCheck}
    >
      <div
        style={{
          display: 'flex',
          padding: '0.5em',
          alignItems: 'center',
          gap: '1em',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <DatePicker
          showIcon
          selected={start_date}
          onChange={setStartDate}
          selectsStart
          isClearable
          startDate={start_date}
          endDate={end_date}
          maxDate={Math.min(new Date(), start_date)}
          showMonthDropdown
          showYearDropdown
          placeholderText="Start date"
          todayButton="Today"
          disabled={loading}
          dateFormat="dd-MM-yyyy"
          customInput={<DateCustomInput/>}
          showTimeSelect
          showTimeInput
        />
        <DatePicker
          showIcon
          selected={end_date}
          onChange={setEndDate}
          selectsEnd
          isClearable
          startDate={start_date}
          endDate={end_date}
          minDate={start_date}
          maxDate={new Date()}
          showMonthDropdown
          showYearDropdown
          placeholderText="End date"
          todayButton="Today"
          disabled={loading}
          dateFormat="dd-MM-yyyy"
          customInput={<DateCustomInput/>}
        />
        <div style={{minWidth: '12em'}}>
          <Select
            options={select_options}
            value={auditevent_type}
            onChange={setAuditEventType}
            isMulti
            blurInputOnSelect
            isDisabled={loading}
            isLoading={!select_options}
            menuPlacement='top'
            placeholder='All types'
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            position: 'relative',
            minWidth: 'min(100%, 20em)',
          }}
        >
          {filter_reg_exp ? null :
            <div
              style={{
                position: 'absolute',
                top: '-4em',
                backgroundColor: '#CCBBBB',
                border: '1px solid red',
                padding: '0.5em',
                borderRadius: '0.2em',
              }}
            >Invalid regular expression</div>
          }
          <input
            type="text"
            placeholder="Filter"
            style={{
              width: '100%',
              border: '1px solid #CCC',
              padding: '0.4em 0.6em',
              borderRadius: '0.2em',
            }}
            onChange={ev => setFilterText(ev.target.value)}
            onBlur={ev => setSearchFilterText(ev.target.value)}
            onKeyUp={ev => {
              if (ev.key !== 'Enter') return;
              ev.target?.blur();
            }}
            value={filter_text}
            disabled={loading}
          />
        </div>
        <div style={{display: 'flex', gap: '0.5em', alignItems: 'center'}}>
          <Toggle
            onChange={e => setLoadOnScroll(e.target.checked)}
            checked={load_on_scroll}
          />
          Load on scroll
        </div>
      </div>
      <div
        style={{
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column-reverse',
          backgroundColor: '#282828',
          padding: '0.5em',
          flexGrow: 1,
        }}
        ref={scrollRef}
      >
        {audit_events.map((ae, i) => {
          const created = new Date(ae.created_at);
          return (
            <AuditEventEntry
              key={`ae_${i}`}
              onClick={() => setFocusedAuditEvent(ae)}
            >
              <span style={{color: '#3f9b31', fontWeight: 'bold'}}>{created.toLocaleDateString('es-Es') + ' ' + created.toLocaleTimeString('es-Es')}</span>
              <span style={{color: '#789ABC', paddingLeft: '0.5em', fontWeight: 'bold'}}>{ae.event_type}</span>
              <span style={{color: '#c54c4c', paddingRight: '0.5em', fontWeight: 'bold'}}>:</span>
              <span style={{color: '#CCC'}}>{ae.friendly_text}</span>
            </AuditEventEntry>
          );
        })}
      {loading || no_more_found ?
        <div style={{
          textAlign: 'center',
          color: '#888888',
          padding: '0.3em',
        }}>{loading ? 'Loading...' : '--- No more entries ---'}</div>
        :
        <button
          style={{
            display: 'block',
          }}
          onClick={loadMore}
        >Load more</button>
      }
      </div>
    </div>
  </>);
};

export default AuditEvents;
