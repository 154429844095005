import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
}                          from 'react';
import Select              from 'react-select';

const getBetaTestingFeatures = dealer_id =>
  fetch(
    `/api/mapi/dealers/${dealer_id}?${new URLSearchParams({
      project: 'beta_testing_features',
    })}`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    }
  ).then(r => r.ok && r.json().then(data => data[0].beta_testing_features) || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)));

const patchBetaTestingFeatures = (dealer_id, features) =>
  fetch(`/api/mapi/dealers/${dealer_id}`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify([
      {op: 'replace', path: `/beta_testing_features`, value: features},
    ]),
  })
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)));

const BetaFeatures = ({ dealer_id, definitions }) => {

  const [ beta_testing_features, setBetaTestingFeatures ] = useState();

  const beta_options = useMemo(() => definitions?.models.dealer.fields.beta_testing_features.values.map(v => ({value: v, label: v})), [definitions]);

  useEffect(() => {
    getBetaTestingFeatures(dealer_id).then(a => console.log(a) || a).then(setBetaTestingFeatures);
  }, [dealer_id]);

  const onBetaFeaturesChange = useCallback(async data => {
    const features = (data || []).map(item => item.value);
    await patchBetaTestingFeatures(dealer_id, features);
    setBetaTestingFeatures(features);
  }, [dealer_id, setBetaTestingFeatures]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '0.5em',
      }}
    >
      <div>Beta testing features:</div>
      <Select
        isMulti
        options={beta_options}
        onChange={onBetaFeaturesChange}
        value={beta_options?.filter(opt => beta_testing_features?.includes(opt.value))}
        styles={{
          container: base => ({...base, flex: 1, minWidth: '48em'}),
        }}
      />
    </div>
  );
};

export default BetaFeatures;
