import React, {
  useState,
}                   from 'react';
import StyledButton from '../StyledButton';

const processFetchResponse = u => u.ok ? u.json() : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`));

const input_style = {
  width: '100%',
  borderRadius: '3px',
  outline: 'none',
  border: '1px solid lightgrey',
  padding: '0.3em 0.6em',
};

const CreditEditor = ({
  close,
  subscription,
  setSubscriptions,
}) => {
  const [ add_credit_amount, setAddCreditAmount ] = useState(0);
  const setBillingCredit = () => fetch(`/api/billing/subscriptions/${subscription._id}/add_credit`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      add_credit_amount,
    }),
  })
    .then(processFetchResponse)
    .then(s => {
      setSubscriptions(subs => ({ ...subs, [s._id]: {...subscription, ...s} }));
      close();
    })
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em'}}>
      <div style={{ display: 'grid', gap: '0.5em', alignItems: 'center', gridTemplateColumns: 'auto 1fr' }}>
        Crédito actual:
        <span>{parseFloat(subscription.remaining_credit).toFixed(2)} {subscription.currency}</span>
      </div>
      <div>
        <div style={{ display: 'grid', gap: '0.5em', alignItems: 'center', gridTemplateColumns: 'auto 1fr' }}>
          Añadir al crédito actual:
          <input style={input_style} type='number' value={add_credit_amount} onChange={e => setAddCreditAmount(e.target.value)} />
        </div>
        <div style={{ fontSize: '0.8em' }}>
          Usa el '.' para introducir los decimales
        </div>
      </div>
      <StyledButton onClick={setBillingCredit}>
        Guardar
      </StyledButton>
    </div>
  );
};

export default CreditEditor;
