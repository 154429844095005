import React             from 'react';
import ReactDOM          from 'react-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes}         from '@fortawesome/free-solid-svg-icons';
import styled            from 'styled-components';

const portal_div = document.getElementById('portal');

const CloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: var(--content-2);
  &:hover{
    color: var(--content-4);
  }
`;

const Modal = DisplayComponent => {
  const Modal = props => {
    const {
      close,
      opened,
      overflow,
      title,
      fullscreen,
      inner_padding = '1em',
      height,
      disable_click_outside,
      width,
    } = props;
    if(!opened) return (<></>);
    const ModalComponent = (
      <div onClick={disable_click_outside ? null : close} style={{
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        display: 'block',
        position: 'fixed',
        zIndex: 999,
      }}>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: '10',
            backgroundColor: 'rgba(0,43,54,0.3)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{
            backgroundColor: '#fdf6e3',
            borderRadius: '6px',
            ...(fullscreen ? {
              width: '90%',
              height: '90%',
            } : {
              width: width,
              maxWidth: !width && '80%',
              minWidth: !width && '40%',
              ...(height ? { height } : {
                maxHeight: '80%',
                minHeight: '20%',
              }),
            }),
            boxShadow: '0px 2px 20px 3px var(--content-3)',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }} onClick={(e) => {e.stopPropagation()}}>
            <div style={{
              backgroundColor: '#eee8d5',
              padding: '1em',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '6px 6px 0px 0px',
            }}>
              <span style={{
                fontSize: '1.1rem',
                flex: 1,
                fontWeight: 'bold',
                lineHeight: '1em',
              }}>
                {title}
              </span>
              <CloseButton
                icon={faTimes}
                onClick={close}
              />
            </div>
            <div
              className='fancy-scrollbar'
              style={{
                padding: inner_padding,
                overflow,
                overflowY: overflow === 'none' ? 'none' : 'auto',
                display: 'flex',
                flexDirection: 'column',
                flex: fullscreen ? 1 : 'auto',
                minHeight: '0em',
              }}
            >
              <DisplayComponent {...props}/>
            </div>
          </div>
        </div>
      </div>
    );
    return ReactDOM.createPortal(ModalComponent, portal_div)
  }
  return Modal;
};

export default Modal;
