import React, {
  useEffect,
  useState,
}                   from 'react';
import {
  FontAwesomeIcon,
}                   from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faPlus,
}                   from '@fortawesome/free-solid-svg-icons';
import AsyncButton  from '../AsyncButton';
import StyledButton from '../StyledButton';
import Select       from 'react-select';

const processFetchResponse = u => u.ok ? u.json() : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`));


const InvoiceLine = ({ line, onChange, deleteLine, subscription }) => {
  return (
    <div style={{
      display: 'flex',
      padding: '1em',
      borderTop: '1px solid black',
      gap: '1em',
      alignItems: 'center',
    }}>
      <input
        value={line.description}
        placeholder="Concepto"
        onChange={e => onChange({...line, description: e.target.value})}
        style={{ flex: 6, minWidth: '0px' }} />
      <input
        value={line.quantity}
        placeholder="Cantidad"
        type="number"
        onChange={e => onChange({...line, quantity: e.target.value})}
        style={{ flex: 1, minWidth: '0px' }} />
      x
      <input
        value={line.unit_amount}
        placeholder="Precio unitario"
        type="number"
        onChange={e => onChange({...line, unit_amount: e.target.value})}
        style={{ flex: 1, minWidth: '0px' }} />
      =
      <span>{(Number(line.quantity) * Number(line.unit_amount) || 0).toLocaleString('es', {style: 'currency', currency: subscription.currency})}</span>
      {deleteLine && <FontAwesomeIcon
        onClick={deleteLine} icon={faTrashAlt}
      /> || <div style={{width: '1em'}}/>}
    </div>
  );
}

const ManualInvoiceCreator = ({
  subscription,
  close,
}) => {
  const [ lines, setLines ] = useState([{}]);
  const [ tax, setTax ] = useState(subscription.tax_rate);
  const [ available_tax_rates, setAvailableTaxRates ] = useState({});
  const [ paid_invoice, setPaidInvoice ] = useState();
  useEffect(() => {
    fetch('/api/billing/tax_rates').then(processFetchResponse).then(setAvailableTaxRates)
  }, [])
  const tax_options = Object
    .entries(available_tax_rates)
    .map(([tax_rate, {description, percentage}]) => ({
      label: `${description} (${percentage}%)`,
      value: tax_rate,
    }));
  const total_amount = lines.reduce((p,line) => p + ((line.unit_amount * line.quantity) || 0), 0) * (((available_tax_rates[tax]?.percentage ?? 0)/100)+1);
  const generateInvoice = () => {
    return fetch(`/api/billing/clients/${subscription.client_id}/subscriptions/${subscription._id}/manual_invoice`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tax_rate: tax,
        lines: lines.map(l => ({...l, unit_amount: Math.round(l.unit_amount * 100)})),
      }),
    }).then(processFetchResponse).then(r => setPaidInvoice(r.paid_invoice))
  }
  return paid_invoice ? (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
    }}>
      La factura por valor de {(paid_invoice.amount_due/100).toLocaleString('es-ES', {style: 'currency', currency: subscription.currency})} se ha emitido con éxito y se ha enviado a {paid_invoice.customer_email}
      <StyledButton onClick={close}>Cerrar</StyledButton>
    </div>
  ) : (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {lines.map((line,i) => (
          <InvoiceLine
            key={i}
            line={line}
            onChange={line => setLines(lines.slice(0, i).concat(line).concat(lines.slice(i + 1)))}
            deleteLine={() => setLines(lines.slice(0, i).concat(lines.slice(i + 1)))}
            subscription={subscription}
          />
        ))}
        <StyledButton style={{ margin: '0em 1em 1em 1em', alignSelf: 'flex-start' }} onClick={() => setLines(l => l.concat({}))}>
          <FontAwesomeIcon icon={faPlus}/> Nueva linea
        </StyledButton>
        <div style={{alignItems: 'center', display: 'flex',padding: '1em', borderTop: '3px double black'}}>
          <div style={{flex: 1}}>Impuesto</div>
          <div style={{flex: 1}}>
            <Select
              isClearable
              options={tax_options}
              value={tax_options.find(o => o.value === tax)}
              onChange={o => setTax(o?.value)}
            />
          </div>
        </div>
        <div style={{padding: '1em', display: 'flex', alignItems: 'center', borderTop: '3px double black'}}>
          <div style={{flex: 1}}>Total</div>
          <div>
            {total_amount.toLocaleString('es-ES', {
              style: 'currency',
              currency: subscription.currency,
            })}
          </div>
        </div>
        <div style={{display: 'flex', gap: '1em'}}>
          <AsyncButton style={{flex: 1}} onClick={generateInvoice}>
            Emitir factura por valor de {total_amount.toLocaleString('es-ES', {style: 'currency', currency: subscription.currency})}
          </AsyncButton>
        </div>
      </div>
    </div>
  );
};

export default ManualInvoiceCreator;
