import React from 'react';

const SearchBar = ({
  onChange,
  dealer_name,
}) => (
  <span>
    Filtrar por dealer
    <input
      value={dealer_name}
      onChange={onChange}
      style={{ marginLeft: '1em' }}
    />
  </span>
);

export default SearchBar;
