import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
}                           from 'react';
import useClickOutside      from '../../hooks/useClickOutside';
import Popup                from '../../components/Popup';
import styled               from 'styled-components';
import Modal                from '../Admin/components/Modal';
import moment               from 'moment';
import StyledButton         from './components/StyledButton';
import AsyncButton          from './components/AsyncButton';
import CancellationConfirm  from './components/CancellationConfirm';
import DiscountEditor       from './components/DiscountEditor';
import CreditEditor         from './components/CreditEditor';
import ManualInvoiceCreator from './components/ManualInvoiceCreator';
import PortalDisplay        from './components/PortalDisplay';
import SubscriptionDetails  from './components/SubscriptionDetails';
import Tabs                 from '../../components/Tabs';
import Tooltip              from '../../components/Tooltip';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import {
  faCaretUp,
  faCaretDown,
  faInfoCircle,
}                           from '@fortawesome/free-solid-svg-icons';
import BaseTable, {
  SortOrder,
  AutoResizer,
  Column,
}                           from 'react-base-table';

const VOICE_URL = 'https://www.twilio.com/docs/usage/api/usage-record#usage-voice';
const SMS_URL = 'https://www.twilio.com/docs/usage/api/usage-record#usage-sms-mms';
const WHATSAPP_URL = 'https://www.twilio.com/docs/usage/api/usage-record#usage-whatsapp';
const PHONE_NUMBERS_URL = 'https://www.twilio.com/docs/usage/api/usage-record#usage-phone-numbers';

const fetchDealers = async () => {
  let skip = 0;
  const limit = 50;
  let has_more = true;
  const dealers = [];
  do {
    const url_search_params = new URLSearchParams({
      skip,
      limit,
      search: 'active=true',
      project: 'friendly_name,billing',
    });
    const [r, next_has_more] = await fetch(`/api/mapi/dealers?${url_search_params}`,     {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(res => res.ok ? res.json().then(d => [d, res.headers.get('mapp-has-more') === 'true']) : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));
    skip += r.length;
    has_more = next_has_more;
    dealers.push(...r);
  } while (has_more)
  return dealers;
};

const USD_EUR = 0.94; // Being conservative

const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object)

const sortData = (data, sort_by, sort_order) => {

  if (sort_by.includes('plans')) {
    const plan = sort_by.split('.')[1];
    return data.sort((d1, d2) => {
      const plan1 = d1.plans.find(p => p.name === plan)?.quantity;
      const plan2 = d2.plans.find(p => p.name === plan)?.quantity;
      if (plan1 == null && plan2 != null)
        return sort_order === SortOrder.ASC ? -1 : 1;
      if (plan1 != null && plan2 == null)
        return sort_order === SortOrder.ASC ? 1 : -1;
      if (plan1 === plan2)
        return 0;
      if (sort_order === SortOrder.ASC)
        return plan1 - plan2;
      else
        return plan2 - plan1;
    });
  }

  return data.sort((d1, d2) => {
    const value1 = resolvePath(d1, sort_by);
    const value2 = resolvePath(d2, sort_by);
    if (value1 == null && value2 != null)
      return sort_order === SortOrder.ASC ? -1 : 1;
    if (value1 != null && value2 == null)
      return sort_order === SortOrder.ASC ? 1 : -1;
    if (value1 === value2)
      return 0;
    if (sort_order === SortOrder.ASC)
      return value1 - value2;
    else
      return value2 - value1;
  });
}

const processFetchResponse = u => u.ok ? u.json() : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`));

const ActionItem = styled.div`
  cursor: pointer;
  padding: 0.4em;
  border-top: ${props => props.i ? '1px solid var(--content-1)' : '0px'};

  &:hover{
    background-color: var(--background-light-2);
  }
`

const ChangeTrialDate = ({
  close,
  setSubscriptions,
  subscription,
}) => {
  const [ date_value, setDateValue ] = useState();
  const updateTrial = trial_ends => {
    return fetch(`/api/billing/subscriptions/${subscription._id}/update_trial`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({trial_ends}),
    }).then(processFetchResponse).then(s => setSubscriptions(subs => ({...subs, [s._id]: s})));
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '0.3em'}}>
      Fecha de fin de trial:
      <input type='date'
        value={date_value}
        onChange={e => setDateValue(e.target.value)}
      />
      <div style={{display: 'grid', gap: '1em', width: '100%', gridTemplateColumns: '1fr 1fr 1fr'}}>
        <StyledButton onClick={close}>
          Cancelar
        </StyledButton>
        <AsyncButton disabled={!date_value} onClick={() => updateTrial(date_value).then(close)}>
          Cambiar trial
        </AsyncButton>
        <AsyncButton onClick={() => updateTrial().then(close)}>
          Finalizar trial ahora
        </AsyncButton>
      </div>
    </div>
  );
}

const ReactivateSubscription = ({
  close,
  setSubscriptions,
  subscription,
}) => {
  const [ date_value, setDateValue ] = useState();
  const reOpenSubscription = trial_ends => {
    return fetch(`/api/billing/subscriptions/${subscription._id}/reopen`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({trial_ends}),
    }).then(processFetchResponse).then(s => setSubscriptions(subs => ({...subs, [s._id]: s})));
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '0.3em'}}>
      Fecha de fin de trial:
      <input type='date'
        value={date_value}
        onChange={e => setDateValue(e.target.value)}
      />
      <div style={{display: 'grid', gap: '1em', width: '100%', gridTemplateColumns: '1fr 1fr'}}>
        <StyledButton onClick={close}>
          Cancelar
        </StyledButton>
        <AsyncButton disabled={!date_value} onClick={() => reOpenSubscription(date_value).then(close)}>
          Reactivar
        </AsyncButton>
      </div>
    </div>
  );
}

const status_colors = {
  trial: 'var(--accent-8)',
  active: 'var(--accent-1)',
  canceled: 'var(--accent-6)',
}

const status_translations = {
  trial: 'Trial',
  active: 'Activa',
  canceled: 'Cancelada',
}


const StatusTag = ({status, trial_ends}) => (
  <div style={{
    padding: '0.1em 0.4em',
    borderRadius: '3px',
    backgroundColor: status_colors[status],
    color: 'var(--background-light-1)',
    textAlign: 'center',
  }}>
    {status_translations[status]}
    {trial_ends && status === 'trial' && (
      <span>
        &nbsp;({moment(trial_ends).fromNow(true)})
      </span>
    )}
  </div>
)

const PAGE_SIZE = 20;

const actions = {
  cancel_subscription: {
    label: 'Cancelar suscripción',
    title: 'Cancelar suscripción',
    Component: CancellationConfirm,
  },
  change_trial_date: {
    label: 'Cambiar fecha de trial',
    title: 'Cambiar fecha de trial',
    Component: ChangeTrialDate,
  },
  edit_discount: {
    label: 'Cambiar descuento',
    title: 'Cambiar descuento',
    Component: DiscountEditor,
  },
  edit_credit: {
    label: 'Cambiar crédito',
    title: 'Cambiar crédito',
    Component: CreditEditor,
  },
  reactivate_subscription: {
    label: 'Re-Activar',
    title: 'Re-Activar',
    Component: ReactivateSubscription,
  },
  send_manual_invoice: {
    label: 'Emitir factura manual',
    title: 'Emitir factura manual',
    Component: ManualInvoiceCreator,
  },
  open_subscription_details: {
    label: 'Detalles de la suscripción',
    title: 'Detalles de la suscripción',
    Component: SubscriptionDetails,
  },
  open_portal: {
    label: 'Abrir portal',
    title: 'Abrir portal',
    Component: PortalDisplay,
    portal_props: {height: '90vh', width: '90vw'},
  },
}

const ActionModal = Modal((props) => {
  const { Component } = actions[props.type];
  return <Component {...props}/>
});

const ActionButton = ({subscription, setActionModalType, setActionModalSubscription}) => {
  const [ display_actions, setDisplayActions ] = useState(false);
  const clickOutsideFn = useCallback(e => { setDisplayActions(null); e.stopPropagation() }, []);
  const click_outside_ref = useClickOutside(clickOutsideFn);
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <StyledButton onClick={() => setDisplayActions(a => a === subscription._id ? null : subscription._id)}>
        Acciones&nbsp;
        <FontAwesomeIcon icon={display_actions === subscription._id ? faCaretUp : faCaretDown} />
      </StyledButton>
      <Popup
        display_content={display_actions === subscription._id}
        portal_element={document.getElementById('portal')}
        placement="bottom"
        offset={[0, 5]}
        content={(
          <div ref={click_outside_ref} style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid var(--content-1)',
            borderRadius: '3px',
            backgroundColor: 'var(--background-light-1)',
          }}>
            {[
              ...(['active', 'trial'].some(t => t === subscription.status) ? ['cancel_subscription'] : []),
              ...(['trial'].some(t => t === subscription.status) ? ['change_trial_date'] : []),
              ...(['canceled'].some(t => t === subscription.status) ? ['reactivate_subscription'] : []),
              'open_subscription_details',
              'edit_discount',
              'edit_credit',
              'send_manual_invoice',
              'open_portal',
            ].map(action => (
              <ActionItem
                key={actions[action].label}
                onClick={() => (setActionModalType(action), setActionModalSubscription(subscription._id))}>
                {actions[action].label}
              </ActionItem>
            ))}
          </div>
        )}
      />
    </div>

  );
}

const TwilioCostHeader = ({ column_title, URL }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '0.3em' }} >
    <span>{column_title}</span>
    <Tooltip
      tag_component={'span'}
      content={<a href={URL}>Ver información sobre costes incluidos</a>}
      portal_element={document.getElementById('portal')}
      component_style={{ display: 'flex', alignItems: 'center' }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </Tooltip>
  </div>
)

const DiscountQuantity = ({subscription}) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '0.3em' }}>
    {subscription.discount && (
      <div>
        <div>
          {subscription.discount.type === 'fixed_amount' && (<span>
            {(subscription.discount.fixed_amount / 100).toLocaleString('es-ES', {
              style: 'currency',
              currency: subscription.currency,
            })}
          </span>)}
          {subscription.discount.type === 'percentage' && (<span>
            {subscription.discount.percentage}%
          </span>)}
        </div>
      </div>
    ) || "—"}
  </div>
)

const getColumns = (prices, setActionModalType, setActionModalSubscription) => ([{
  key: 'client.name',
  title: 'Nombre',
  dataKey: 'client.name',
  minWidth: 160,
  frozen: Column.FrozenDirection.LEFT,
}, {
  key: 'client.friendly_name',
  title: 'Nombre en Walcu',
  dataKey: 'client.dealer_friendly_name',
  minWidth: 160,
  frozen: Column.FrozenDirection.LEFT,
}, {
  key: 'Acciones',
  title: 'Acciones',
  dataKey: 'client._id',
  minWidth: 100,
  frozen: Column.FrozenDirection.LEFT,
  cellRenderer: ({ rowData: s }) => (
    <ActionButton
      subscription={s}
      setActionModalType={setActionModalType}
      setActionModalSubscription={setActionModalSubscription}
    />
  ),
}, {
  key: 'client.email',
  title: 'Email',
  minWidth: 200,
  dataKey: 'client.email',
}, {
  key: 'status',
  title: 'Estado',
  minWidth: 200,
  dataKey: 'status',
  cellRenderer: ({ rowData: s }) => (<StatusTag status={s.status} trial_ends={s.trial_ends} />),
}, {
  key: 'discount_quantity',
  title: 'Cantidad descuento',
  minWidth: 100,
  cellRenderer: ({ rowData: s }) => (<DiscountQuantity subscription={s} />),
  dataKey: 'discount_quantity',
}, {
  key: 'last_invoice_amount',
  title: 'Última factura',
  minWidth: 100,
  sortable: true,
  cellRenderer: ({ rowData: s }) => ((s => s && s / 100)(s.last_invoice_amount))?.toLocaleString('es-ES', {
    style: 'currency',
    currency: s.currency,
  }),
  dataKey: 'last_invoice_amount',
}, {
  key: 'cycle_ends',
  title: 'Fin de ciclo',
  minWidth: 100,
  dataKey: 'cycle_ends',
  cellRenderer: ({ rowData: s }) => (d => d && moment(d).isValid && moment(d).format('dd DD/MM/YY HH:mm') || '-')(s.next_invoice_date || s.trial_ends),
}, ...(Object
  .entries(prices)
  .sort(([a], [b]) => a === 'base' ? -1 : b === 'base' ? 1 : 0)
  .filter(([, plan]) => plan.metadata.walcu_plan_name !== 'manual')
  .map(([name]) => ({
    key: `plans.${name}`,
    title: `Planes ${name}`,
    minWidth: 100,
    sortable: true,
    cellRenderer: ({ rowData: s }) => (p => p?.quantity ?? p?.usage ?? 0)(s.plans.find(p => p.name === name)) + (s.min_quantities?.[name] ? ` (mín ${s.min_quantities?.[name]})` : ''),
  }))
), {
  key: 'client.monthly_costs.voice',
  title: 'Voz',
  sortable: true,
  dataKey: 'client.monthly_costs.voice',
  cellRenderer: ({ cellData }) => cellData?.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
  headerRenderer: ({ column }) => (<TwilioCostHeader column_title={column.title} URL={VOICE_URL} />),
  minWidth: 100,
}, {
  key: 'client.monthly_costs.phone_numbers',
  title: 'Números de teléfono',
  sortable: true,
  dataKey: 'client.monthly_costs.phone_numbers',
  cellRenderer: ({ cellData }) => cellData?.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
  headerRenderer: ({ column }) => (<TwilioCostHeader column_title={column.title} URL={PHONE_NUMBERS_URL} />),
  minWidth: 180,
}, {
  key: 'client.monthly_costs.sms',
  title: 'SMS',
  minWidth: 100,
  sortable: true,
  dataKey: 'client.monthly_costs.sms',
  cellRenderer: ({ cellData }) => cellData?.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
  headerRenderer: ({ column }) => (<TwilioCostHeader column_title={column.title} URL={SMS_URL} />),
},{
  key: 'client.monthly_costs.whatsapp',
  title: 'Whatsapp',
  minWidth: 100,
  sortable: true,
  dataKey: 'client.monthly_costs.whatsapp',
  cellRenderer: ({ cellData }) => cellData?.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
  headerRenderer: ({ column }) => (<TwilioCostHeader column_title={column.title} URL={WHATSAPP_URL} />),
}, {
  key: 'client.monthly_costs.email',
  title: 'Email',
  sortable: true,
  minWidth: 100,
  dataKey: 'client.monthly_costs.email',
  cellRenderer: ({ cellData }) => cellData?.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
}, {
  key: 'client.last_month_consumed_credit',
  title: 'Créditos consum',
  sortable: true,
  minWidth: 100,
  dataKey: 'client.last_month_consumed_credit',
  cellRenderer: ({ cellData }) => cellData?.toFixed(2),
}, {
  key: 'client.monthly_costs.total',
  title: 'Total',
  sortable: true,
  minWidth: 100,
  dataKey: 'client.monthly_costs.total',
  cellRenderer: ({ cellData }) => cellData?.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
}, {
  key: 'net_cost',
  title: 'Consumo Neto',
  minWidth: 100,
  dataKey: 'client',
  cellRenderer: ({ rowData: s }) => (((s.client.monthly_costs?.total * USD_EUR) - s.client.last_month_consumed_credit) || 0).toLocaleString('es-ES', {
    style: 'currency',
    currency: 'USD',
  }),
}]).map(c => ({ ...c, width: c.minWidth }));

const default_sort = {
  key: 'Planes base',
  order: SortOrder.DESC,
};

const TableCell = ({ cellData }) => (
  <span title={`${cellData}`}>{cellData}</span>
);

const TableHeaderCell = ({ column: { title } }) => (
  <span title={title}>{title}</span>
);

const BillingTab = ({
  products,
  product,
  subscriptions,
  setSubscriptions,
  updateSubscriptionFlags,
  updateClientMemo,
  updateSubscriptionMinQuantities,
  sort_key: props_sort_key,
  sort_direction,
  onColumnSort,
  dealers,
}) => {
  const [ action_modal_type, setActionModalType ] = useState(null);
  const [ action_modal_subscription, setActionModalSubscription ] = useState(null);

  const data = useMemo(() => {
    const data_with_dealer_name = Object.values(subscriptions).map(s => ({
      ...s,
      client: {
        ...s.client,
        dealer_friendly_name: dealers.find(d => d.billing?.client_id === s.client?._id)?.friendly_name,
      },
    }));

    return sortData(Object.values(data_with_dealer_name), props_sort_key, sort_direction);
  }, [subscriptions, props_sort_key, sort_direction, dealers]);

  const sortBy = useMemo(() => ({ key: props_sort_key, order: sort_direction }), [props_sort_key, sort_direction]);
  const prices = products[product].prices;
  const columns = getColumns(prices, setActionModalType, setActionModalSubscription);

  return (
    <div style={{ height: '100%' }}>
      <ActionModal
        overflow="none"
        opened={action_modal_type}
        type={action_modal_type}
        title={actions[action_modal_type]?.title}
        close={() => setActionModalType(null)}
        subscription={subscriptions[action_modal_subscription]}
        setSubscriptions={setSubscriptions}
        updateSubscriptionFlags={updateSubscriptionFlags}
        updateClientMemo={updateClientMemo}
        updateSubscriptionMinQuantities={updateSubscriptionMinQuantities}
        prices={prices}
        {...actions[action_modal_type]?.portal_props ?? {}}
      />
      <AutoResizer >
        {({ width, height }) => (
          <BaseTable
            columns={columns}
            data={data}
            fixed
            width={width}
            height={height}
            onColumnSort={onColumnSort}
            sortBy={sortBy}
            components={{ TableCell, TableHeaderCell }}
          />)
        }
      </AutoResizer>
    </div>
  );
}

const Billing = () => {
  document.title = 'Facturación - Walcu Penguin';
  const [ subscriptions, setSubscriptions ] = useState({});
  const [ has_more, setHasMore ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ dealers, setDealers ] = useState([]);
  const [ products, setProducts ] = useState(0);
  const [sort_key, setSortKey] = useState(default_sort.key);
  const [sort_direction, setSortDirection] = useState(default_sort.order);
  const [ status_filter, setStatusFilter ] = useState({
    active: true,
    trial: true,
  });
  const [ name_filter, setNameFilter ] = useState('');
  const [ selected_tab, setSelectedTab ] = useState('WALCUCRM');

  useEffect(() => {
    fetch(`/api/billing/products`)
      .then(processFetchResponse)
      .then(setProducts)
  }, []);

  useEffect(() => {
    fetchDealers()
      .then(setDealers)
  }, []);

  useEffect(() => {
    const query = new URLSearchParams({
      status: Object.entries(status_filter).filter(([,v]) => v).map(([k]) => k),
      products: [selected_tab],
      name: name_filter,
      skip: page*PAGE_SIZE,
      limit: PAGE_SIZE,
      sort_by: sort_key,
      sort_direction: sort_direction,
    });
    fetch(`/api/billing/subscriptions?${query}`)
      .then(u => u.ok ? Promise.all([u.json(), u.headers.get('MAPP-has-more')]) : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`)))
      .then(([subs, has_more]) => {
        setSubscriptions(Object.fromEntries(subs.map(s => [s._id, s])));
        setHasMore(has_more === 'true');
      })
  }, [status_filter, name_filter, selected_tab, sort_key, sort_direction, page]);

  const updateSubscriptionFlags = useCallback((_, subscription_id, flags) => (
    fetch(`/api/billing/subscriptions/${subscription_id}/flags`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(flags),
    })
      .then(processFetchResponse)
  ), []);
  const updateClientMemo = useCallback((client_id, memo) => (
    fetch(`/api/billing/clients/${client_id}/memo`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ memo }),
    }).then(processFetchResponse)
  ), []);

  const updateSubscriptionMinQuantities = useCallback((client_id, subscription_id, min_quantities) => (
    fetch(`/api/billing/subscriptions/${subscription_id}/min_quantities`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(min_quantities),
    }).then(processFetchResponse)
  ), []);

  const onColumnSort = useCallback(({ key, order }) => {
    setSortKey(key);
    setSortDirection(order);
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      maxWidth: '100%',
      maxHeight: '100%',
      padding: '0 1em 0 0',
      height: '100%',
      overflow: 'hidden',
    }}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '1em', gap: '0.5em'}}>
        Nombre:
        <input type='text' value={name_filter} onChange={e => setNameFilter(e.target.value)}/>
        <div style={{flex: 1}}/>
        Filtrar por estado:
        {['active', 'trial', 'canceled'].map(item => (
          <div
            key={item}
            style={{
              display: 'flex',
              gap: '0.3em',
              alignItems: 'center',
              backgroundColor: 'var(--background-light-2)',
              border: '1px solid var(--content-1)',
              borderRadius: '3px',
              cursor: 'pointer',
              padding: '0.5em',
            }}
            onClick={() => setStatusFilter(prev => ({
              ...prev,
              [item]: !prev[item],
            }))}
          >
            <input type='checkbox' readOnly checked={Boolean(status_filter[item])}/>
            {status_translations[item]}
          </div>
        ))}
        <div style={{display: 'flex', gap: '0.5em', alignItems: 'center'}}>
          <StyledButton disabled={!page} onClick={() => setPage(p => p-1)}>Anterior</StyledButton>
          Página {page+1}
          <StyledButton disabled={!has_more} onClick={() => setPage(p => p+1)}>Siguiente</StyledButton>
        </div>
      </div>
      <Tabs
        controlled
        active_tab_id={selected_tab}
        setActiveTabId={setSelectedTab}
        tabs={Object.keys(products).map(product => ({
          id: product,
          name: product,
          component: BillingTab,
          props: {
            products,
            product,
            subscriptions,
            setSubscriptions,
            sort_key,
            setSortKey,
            sort_direction,
            setSortDirection,
            onColumnSort,
            updateSubscriptionFlags,
            updateClientMemo,
            updateSubscriptionMinQuantities,
            dealers,
          },
        }))}
      />
    </div>
  );
};

export default Billing;
