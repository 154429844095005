import React, {
  useEffect,
  useRef,
} from 'react';


const LoginAs = ({
  dealer_id,
  user_id,
}) => {

  const loginButtonRef = useRef(null);

  const doLoginAs = user_id => {
    fetch(`/api/login_as/${dealer_id}/${user_id}`)
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
      .then(decoded_token => {
        window.open(`${process.env.REACT_APP_MAPP_WEB_CLIENT_BASE_URL}autologin?decoded_token=${encodeURIComponent(JSON.stringify(decoded_token))}`)
      })
      .catch(err => alert(err.toString()))
  };

  useEffect(() => {
    if (loginButtonRef.current)
      loginButtonRef.current.focus();
  }, [ dealer_id, user_id ]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}>
      {dealer_id && user_id && (
        <button ref={loginButtonRef} style={{padding: '0.5em 1em', borderRadius: '0.3em'}} onClick={() => doLoginAs(user_id)}>
          Entrar
        </button>
      )}
    </div>
  );
};

export default LoginAs;
