import React, {
  useState,
}                from 'react';
import moment    from 'moment';
import 'moment/locale/es';

moment.locale('es');

const input_style = {
  border: '1px solid #DDD',
  padding: '0.2em 0.5em',
  borderRadius: '0.2em',
};

const download = (filename, blob) => {
  const element = document.createElement('a');
  const url = URL.createObjectURL(blob);
  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const getMessageById = (dealer_id, message_id) => fetch(`/api/mapi/dealers/${dealer_id}/emails?q=${encodeURIComponent(JSON.stringify({message_id}))}`)
  .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
  .then(r => r && r[0] && r[0]._id);

const getEml = (dealer_id, email_id) => fetch(`/api/mappex/services/mail/incoming/dealers/${dealer_id}/emails/${email_id}/eml`).then(r => r.ok && r.blob() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)));

const downloadEml = async (dealer_id, message_id, crm_id) => {

  crm_id ||= await getMessageById(dealer_id, message_id);
  const eml = await getEml(dealer_id, crm_id);
  download(`${crm_id}.eml`, eml);
}

const EmlDownloader = ({
  dealer_id,
}) => {
  const [ message_id, setMessageId ] = useState();
  const [ crm_id, setCrmId ] = useState();
  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '1em'}}>
      <div style={{display: 'flex', flexDirection: 'column', gap: '0.5em', flexGrow: 1}}>
        <input
          value={message_id}
          onChange={e => setMessageId(e.target.value)}
          placeholder={'Message-ID'}
          disabled={crm_id}
          style={input_style}
        />
        <input
          value={crm_id}
          onChange={e => setCrmId(e.target.value)}
          placeholder={'ID CRM'}
          disabled={message_id}
          style={input_style}
        />
      </div>
      <button
        style={{
          border: 'none',
          background: !message_id && !crm_id ? '#DDD' : '#CCC',
          padding: '0.5em 1em',
          borderRadius: '0.3em',
        }}
        disabled={!message_id && !crm_id}
        onClick={() => downloadEml(dealer_id, message_id, crm_id)}
      >
        Descargar .eml
      </button>
    </div>
  );
};

export default EmlDownloader;
