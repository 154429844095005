import React, {
  useState,
  useEffect,
}                          from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrashAlt,
  faUserPlus,
}                          from '@fortawesome/free-solid-svg-icons';
import Modal               from './components/Modal';
import UserForm            from './components/UserForm';
import UserGroupForm       from './components/UserGroupForm';
import styled              from "styled-components"

const UserFormModal      = Modal(UserForm);
const UserGroupFormModal = Modal(UserGroupForm);

const permission_colors = {
  'Billing': '#89ABCD',
  'Developer': '#CD89AB',
  'CustomerSuccess': '#63B7A7',
  'RegisterRequests': '#D38912',
  'Library': '#E6d867',
  'LoginAs': '#CDAB89',
  'Master&Commander': '#FF8989',
  'SystemStatus': '#AB89CD',
  'Tasator': '#67A8FF',
  'Twilio': '#ABCD89',
};

const Row = styled.tr`
  &:hover {
    background-color: var(--background-light-2);
  }
`;

const UserRow = ({
  editUser,
  user,
  show_inactive,
  deleteUser,
  agent_id,
}) => {

  if (user.active || show_inactive) return (
    <tr>
      <td>{user.name}</td>
      <td>{user.surname}</td>
      <td>{user.email}</td>
      <td>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '0.5em'}}>
          {(user.permissions || []).sort().map(permission => (
            <span
              key={permission}
              style={{backgroundColor: permission_colors[permission] || '#AAAAAA', padding: '0 0.3em', borderRadius: '0.3em'}}
            >
              {permission}
            </span>
          ))}
        </div>
      </td>
      <td>
        <button
          onClick={() => editUser(user)}
          className='icon-button'
          name={user._id}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
          className='icon-button'
          onClick={() => deleteUser(user)}
          name={user._id}
          disabled={agent_id === user._id}
        >
          <FontAwesomeIcon icon={user.active? faTrashAlt : faUserPlus}/>
        </button>
      </td>
    </tr>
  );
  return null;
}

const UserTable = ({
  users,
  editUser,
  deleteUser,
  agent_id,
  filter_text,
  show_inactive,
}) => {
  if (users === null) return null;
  const rows = users
    .filter(user => ['name', 'surname', 'email'].some(attribute =>
      user[attribute]?.toLowerCase().includes(filter_text.toLowerCase()),
    ))
    .map(user => (
      <UserRow
        user={user}
        key={`${user._id}`}
        editUser={editUser}
        deleteUser={deleteUser}
        agent_id={agent_id}
        show_inactive={show_inactive}
      />
    ));

  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Email</th>
          <th>Permisos</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

const UserGroupTable = ({
  user_groups,
  dealers,
  users,
  editUserGroup,
  deleteUserGroup,
}) => {

  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Miembros</th>
          <th>Dealers Visibles</th>
          <th>Paises visibles</th>
          <th>Blacklisted</th>
          <th>Borrar</th>
        </tr>
      </thead>
      <tbody>
        {user_groups?.map(user_group => (
          <Row
            key={user_group._id}
            onClick={() => editUserGroup(user_group)}
          >
            <td>{user_group.name}</td>
            <td>
              <div style={{display: 'flex', gap: '0.5em', flexWrap: 'wrap'}}>
              {
                user_group.members
                  ?.map(member_id => [users?.find(u => u._id === member_id), member_id])
                  .sort(([u]) => u ? -1 : 1)
                  .map(([u, member_id], i) => u ? (
                    <span key={u._id}>{u.name} {u.surname} <span style={{color: 'gray'}}>({u.email})</span></span>
                  ) : (
                    <span key={i} style={{
                      color: 'red',
                      border: '1px solid red',
                      borderRadius: '0.3em',
                      padding: '0 0.2em',
                    }}>{member_id}</span>
                  ))
              }
              </div>
            </td>
            <td>
              {user_group.can_view_all ? (
                <div style={{color: '#AA6666', fontWeight: 'bold', backgroundColor: '#DDD', textAlign: 'center'}}>Todos</div>
              ) : (
                <div style={{display: 'flex', gap: '0.5em', flexWrap: 'wrap', alignItems: 'baseline' }}>
                {
                  user_group.dealers
                    ?.map(dealer_id => [dealers?.find(d => d._id === dealer_id), dealer_id])
                    .sort(([d]) => d ? -1 : 1)
                    .map(([d, dealer_id], i) => d ? (
                      <span key={d._id}>{d.friendly_name} {d.surname} <span style={{color: 'gray'}}>({d.domain})</span></span>
                    ) : (
                      <span key={i} style={{
                        color: '#FF4444',
                        fontSize: 'small',
                      }}>{dealer_id}</span>
                    ))
                }
                </div>
              )}
            </td>
            <td>
              {user_group.can_view_all ? (
                <div style={{color: '#AA6666', fontWeight: 'bold', backgroundColor: '#DDD', textAlign: 'center'}}>Todos</div>
              ) : (
                <div style={{display: 'flex', gap: '0.5em', flexWrap: 'wrap'}}>
                  {user_group.country_codes?.map(cc => <span key={cc}>{cc}</span>)}
                </div>
              )}
            </td>
            <td>
              {user_group.can_view_all ? (
                <div style={{color: '#AA6666', fontWeight: 'bold', backgroundColor: '#DDD', textAlign: 'center'}}>Ninguno</div>
              ) : (
                <div style={{display: 'flex', gap: '0.5em', flexWrap: 'wrap', alignItems: 'baseline' }}>
                {
                  user_group.blacklisted_dealers
                    ?.map(dealer_id => [dealers?.find(d => d._id === dealer_id), dealer_id])
                    .sort(([d]) => d ? -1 : 1)
                    .map(([d, dealer_id], i) => d ? (
                      <span key={d._id}>{d.friendly_name} {d.surname} <span style={{color: 'gray'}}>({d.domain})</span></span>
                    ) : (
                      <span key={i} style={{
                        color: '#FF4444',
                        fontSize: 'small',
                      }}>{dealer_id}</span>
                    ))
                }
                </div>
              )}
            </td>
            <td style={{textAlign: 'center'}}>
              <button
                className='icon-button'
                onClick={ev => {
                  ev.stopPropagation();
                  deleteUserGroup(user_group);
                }}
                style={{margin: 0}}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </td>
          </Row>
        ))}
      </tbody>
    </table>
  );
};

const SearchBar = ({
  filter_text,
  onFilterTextChange,
}) => (
  <form>
    <input
      type='text'
      placeholder='Search...'
      value={filter_text}
      onChange={e => onFilterTextChange(e.target.value)}
    />
  </form>
);

const fetchDealers = async () => {
  let has_more = false;
  let skip = 0;
  let fetched_dealers = [];
  do {
    const [next_dealers, next_has_more] = await fetch(`/api/mapi/dealers?${new URLSearchParams({
      q: JSON.stringify({ active: true }),
      skip,
      project: '_id=1,friendly_name=1,domain=1,country_code=1',
    })}`)
      .then(res => res.ok ? (
        Promise.all([res.json(), res.headers.get('mapp-has-more')])
      ) : (
        res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
      ));
    skip += 50;
    has_more = next_has_more === 'true';
    fetched_dealers = fetched_dealers.concat(next_dealers);
  } while(has_more);
  return fetched_dealers;
};

const Admin = ({
  user,
}) => {
  document.title = 'Admin - Walcu Penguin';

  const [dealers, setDealers] = useState(null);
  const [users, setUsers] = useState(null);
  const [user_groups, setUserGroups] = useState(null);
  const [filter_text, setFilterText] = useState('');
  const [is_user_modal_opened, setIsUserModalOpened] = useState(false);
  const [is_user_group_modal_opened, setIsUserGroupModalOpened] = useState(false);
  const [user_detail, setUserDetail] = useState(null);
  const [usergroup_detail, setUserGroupDetail] = useState(null);
  const [show_inactive, setShowInactive] = useState(false);


  useEffect(() => {
    fetchDealers().then(setDealers);
  }, []);

  const toggleUserModal = () => {
    setIsUserModalOpened(prev_state => !prev_state);
    if (is_user_modal_opened) setUserDetail(null);
  }

  useEffect(() => {
    fetch('/api/users')
    .then(u => u.ok
      ? u.json()
      : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} when fetching user data: ${t}`))
    )
    .then(u => setUsers(u))
    .catch(e => alert(e));

    fetch('/api/usergroups')
      .then(u => u.ok
        ? u.json()
        : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} when fetching usergroup data: ${t}`))
      )
      .then(u => setUserGroups(u))
      .catch(e => alert(e));
  }, []);

  const resetPassword = (user) => {
    fetch(`/api/users/${user._id}/resetpassword`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    }).then(u => u.ok ?
      u.json()
      :
      u.text().then(t => Promise.reject(`Unexpected error code ${u.status} while resetting user password: ${t}`)))
      .then(r => alert(`El código de activación del usuario es: ${r.otp}`))
      .catch(e => alert(e));
  };

  const createUserGroup = (user_group) => {
    fetch(`/api/usergroups/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user_group),
    })
    .then(r => r.ok
      ? r.json()
      : r.text().then(t => Promise.reject(`Unexpected error code ${r.status} at ${r.url}: ${t}`))
    )
    .then(new_user_group => {
      setUserGroups(current_user_groups => current_user_groups.concat(new_user_group))
    })
    .catch(e => alert(e));
  };

  const updateEntity = (entity, entity_name, updatedSetter) => {
    fetch(`/api/${entity_name}/${entity._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(entity),
    })
      .then(r => r.ok
        ? r.json()
        : r.text().then(t => Promise.reject(`Unexpected error code ${r.status} at ${r.url}: ${t}`))
      )
      .then(updated_entity => {
        updatedSetter?.(current_entities => current_entities.map(current_entity => {
          if (current_entity?._id === updated_entity._id) return updated_entity;
          return current_entity;
        }));
      })
      .catch(e => alert(e));
  };

  const saveUser = (user) => {
    toggleUserModal();
    if (user._id) updateEntity(user, 'users', setUsers);
    else resetPassword(user);
  };
  const deleteUser = (user) => {
    user.active = !user.active;
    updateEntity(user, 'users', setUsers);
  };

  const saveUserGroup = user_group => {
    if (user_group._id) updateEntity(user_group, 'usergroups', setUserGroups);
    else createUserGroup(user_group);
    setIsUserGroupModalOpened(false);
  };

  const editUser = (user) => {
    setUserDetail(user);
    toggleUserModal();
  };

  const handleFilterTextChange = (filter_text) => {
    setFilterText(filter_text);
  };

  const handleShowInactiveToggle = (event) => {
    setShowInactive(event.target.checked);
  };

  return (<>
    <UserFormModal
      opened={is_user_modal_opened}
      close={() => setIsUserModalOpened(false)}
      title={user_detail ? 'Actualizar usuario' : 'Crear usuario'}
      user={user_detail}
      saveUser={saveUser}
      resetPassword={resetPassword}
      handleClose={toggleUserModal}
      overflow='none'
    />
    <UserGroupFormModal
      opened={is_user_group_modal_opened}
      close={() => setIsUserGroupModalOpened(false)}
      title={usergroup_detail ? 'Actualizar grupo' : 'Crear grupo'}
      dealers={dealers}
      users={users}
      user_group={usergroup_detail}
      saveUserGroup={saveUserGroup}
      overflow='none'
    />
    <div style={{padding: '1em', display: 'flex', flexDirection: 'column', width: '100%'}}>
      <div style={{display: 'flex', alignItems: 'baseline', gap: '2em'}}>
        <div style={{fontSize: '2em', fontWeight: 'bold'}}>Usuarios</div>
        <SearchBar
          filter_text={filter_text}
          onFilterTextChange={handleFilterTextChange}
        />
        <div style={{flexGrow: 1}} />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <label>Mostrar usuarios inactivos</label>
          <div className='divider' />
          <label className='switch'>
            <input
              name='show_inactive'
              type='checkbox'
              checked={show_inactive}
              onChange={handleShowInactiveToggle}
              className='slider-round'
            />
          </label>
        </div>
        <button type='button' className='button' onClick={toggleUserModal}>Nuevo usuario</button>
      </div>
      <UserTable
        users={users}
        filter_text={filter_text}
        editUser={editUser}
        deleteUser={deleteUser}
        agent_id={user._id}
        show_inactive={show_inactive}
      />
    </div>
    <div style={{padding: '1em', display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
      <div style={{display: 'flex', alignItems: 'baseline', gap: '2em', justifyContent: 'space-between'}}>
        <div style={{fontSize: '2em', fontWeight: 'bold'}}>Grupos</div>
        <button
          type='button'
          className='button'
          onClick={() => {
            setUserGroupDetail(null);
            setIsUserGroupModalOpened(true);
          }}>Nuevo grupo</button>
      </div>
      <UserGroupTable
        user_groups={user_groups?.filter(ug => ug.active)}
        dealers={dealers}
        users={users}
        editUserGroup={user_group => {
          setUserGroupDetail(user_group);
          setIsUserGroupModalOpened(true);
        }}
        deleteUserGroup={user_group => {
          const delete_confirmation = window.confirm(`¿Seguro que quieres eliminar el grupo '${user_group.name}'?`);
          if (delete_confirmation) {
            user_group.active = false;
            updateEntity(user_group, 'usergroups', setUserGroups);
          }
        }}
      />
    </div>
  </>);
};

export default Admin;
