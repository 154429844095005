import CustomerSuccessTable from './components/CustomerSuccessTable.js';

const CustomerSuccess = () => {
  document.title = 'Customer Success - Walcu Penguin';
  return (
    <div style={{padding: '1em'}}>
      <h1>Customer success</h1>
      <CustomerSuccessTable />
    </div>
  );
};

export default CustomerSuccess;
