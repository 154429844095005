import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
  useRef,
}                              from 'react';
import styled                  from "styled-components"
import {FontAwesomeIcon}       from '@fortawesome/react-fontawesome';
import {
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip                 from './Tooltip';
import useClickOutside         from '../hooks/useClickOutside';

const Comp = styled.div`
  width: 100%;
  padding: 0.25em;
  border-radius: 3px;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  color: var(--content-4),
  background-color: ${props => props.selected ? 'var(--background-light-2)' : 'var(--background-light-1)'};
  &:hover {
    background-color: var(--background-light-2);
  }
`;

const TabOptionItem = props => (
  <Comp
    selected={props.selected}
    onClick={props.onClick}
  >
    {props.children}
  </Comp>
);

const TabDiv = styled.div`
  outline: none;
  min-width: 0;
  max-width: ${props => props.stacked ? 'none' : props.max_tab_width};
  flex: ${props => props.stacked ? 1 : 0} 1 ${props => props.stacked ? 'auto' : props.max_tab_width};
  flex-shrink: ${props => props.active ? 0 : 1};
  align-self: flex-end;
  overflow: hidden;
  transition: flex-shrink 100ms ease-out;

  :hover {
    flex-shrink: 0;
  }
`

const TabHeader = ({group, active_tab_id, highlight_tab_ids, setActiveTabId, small}) => {
  const active_tab_in_group = group.some(t => t.id === active_tab_id);
  const highlight_tab = group.some(t => highlight_tab_ids.some(h_id => h_id === t.id));
  const [ display_more, setDisplayMore] = useState(false);
  const [ group_selected_tab, setGroupSelectedTab ] = useState(group.find(t => t.id === active_tab_id));
  const tag_ref = useRef();
  const closeDisplayMore = useCallback(e => {
    if (!tag_ref.current?.contains(e.target)) {
      e.stopPropagation();
      setDisplayMore(false);
    }
  }, []);
  const outside_ref = useClickOutside(closeDisplayMore);
  return (
    <div
      ref={tag_ref}
      style={{
        display: 'flex',

        flexGrow: small ? 0 : 1,
        flexShrink: small ? 0 : 1,
        flexBasis: 'auto',
        maxWidth: '100%',
        padding: '0.5em ',
        backgroundColor: active_tab_in_group && 'var(--background-light-1)' || (highlight_tab && 'var(--accent-8)') || 'var(--background-light-2)',
        border: '1px solid var(--content-1)',
        borderRadius: `0.5em 0.5em 0 0`,
        borderBottomColor: active_tab_in_group ? 'var(--background-light-1)' : 'var(--content-1)',
        borderTopColor: group[0].color || 'var(--content-1)',
        borderTopWidth: group[0].color ? '4px' : '1px',

        cursor: active_tab_in_group && 'default' || 'pointer',
      }}
      onClick={() => {
        setActiveTabId(group_selected_tab?.id || group[0]?.id);
      }}
      onMouseEnter={() => {
        setDisplayMore(true)
      }}
      onMouseLeave={() => {
        setDisplayMore(false)
      }}
    >
      {group.length > 1 ? (
        <Tooltip
          visible={display_more}
          delay={100}
          component_style={{width: '100%', backgroundColor: 'inherit'}}
          content={(
            <div ref={outside_ref} style={{
              fontSize: 'var(--fonts-default-size-medium)',
              backgroundColor: 'var(--background-light-1)',
              maxWidth: '20em',
              margin: 0,
              left: 0,
              overflow: 'auto',
              overflowY: 'auto',
            }}>
            {group.map(tab => (
              <TabOptionItem
                key={tab.id}
                selected={tab.id === active_tab_id}
                onClick={e => {
                  e.stopPropagation();
                  setGroupSelectedTab(tab);
                  setActiveTabId(tab.id);
                  setDisplayMore(false)
                }}
              >
                {React.isValidElement(tab.name) ? React.cloneElement(tab.name, {tooltip_disabled: true}) : tab.name}
              </TabOptionItem>
            ))}
            </div>
          )}
        >
          <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'inherit'}}>
            <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', backgroundColor: 'inherit'}}>
              {group_selected_tab ?
                React.isValidElement(group_selected_tab.name) ? React.cloneElement(group_selected_tab.name, {tooltip_disabled: true}) : group_selected_tab.name
                :
                React.isValidElement(group[0].name) ? React.cloneElement(group[0].name, {tooltip_disabled: true}) : group[0].name
              }
            </div>
            <span style={{
              display: 'inline-flex',
              width: '1.4em',
              height: '1.4em',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'var(--content-3)',
              borderRadius: '2px',
              marginLeft: '0.25em',
              backgroundColor: active_tab_in_group ? 'var(--background-light-2)' : 'var(--background-light-1)',
            }}>
              {group.length}
            </span>
            <div style={{flex: 0, marginLeft: '0.25em'}}>
              <FontAwesomeIcon icon={display_more ? faCaretUp : faCaretDown}/>
            </div>
          </div>
        </Tooltip>
      ) : (
        <div style={{width: '100%', backgroundColor: 'inherit', overflow: 'hidden', whiteSpace: 'nowrap'}}>
          {group[0].name}
        </div>
      )}
    </div>
  )
};

const Tabs = props => {
  const {
    tabs = [],
    small_tabs = [],
    children,
    containerStyle = {},
    tabs_margin_right = '0px',
    max_tab_width = 'auto', // Override recommended, mandatory if sorting is enabled in order to avoid graphical glitches and overall uglyness
    height= '100%',
    width='auto',
    active_tab_id: props_active_tab_id,
    highlight_tab_ids=[],
    setActiveTabId: setPropsActiveTabId,
    controlled,
  } = props;
  const groupedTabs = useMemo(() => tabs && tabs.map && tabs
    .map(tab => ({...tab, group: (tab.group || tab.name)}))
    .reduce((prev, tab) => ({ ...prev, [tab.group]: (prev[tab.group] || []).concat(tab)}),{}) || {}
  , [tabs]);
  const groupedSmallTabs = useMemo(() => small_tabs && small_tabs.map && small_tabs
    .map(tab => ({...tab, group: (tab.group || tab.name)}))
    .reduce((prev, tab) => ({ ...prev, [tab.group]: (prev[tab.group] || []).concat(tab)}),{}) || {}
  , [small_tabs]);
  const [ state_active_tab_id, setStateActiveTabId  ] = useState(tabs[0]?.id || small_tabs[0]?.id);
  const active_tab_id = controlled ? props_active_tab_id : state_active_tab_id;
  const setActiveTabId = controlled ? setPropsActiveTabId  : setStateActiveTabId;

  const crowded_cutoff = 10;

  useEffect(() => {
    const all_tabs = tabs.concat(small_tabs);
    !all_tabs?.find(t => t && t.id === active_tab_id) && all_tabs[0] && setActiveTabId(all_tabs[0].id)
  }, [tabs, small_tabs, active_tab_id, setActiveTabId]);

  const active_tab = tabs?.find(t => t.id === active_tab_id) || small_tabs?.find(t => t.id === active_tab_id) || tabs[0] || small_tabs[0];

  const crowded = (groupedTabs && Object.keys(groupedTabs).length > crowded_cutoff);
  const stacked = crowded;

  return (
    <div style={{height, width, display: 'flex', flexDirection: 'column', marginTop: stacked ? '2.5em' : '0' }}>
      <div style={{
        display: 'flex',
        userSelect: 'none',
        flexWrap: stacked ? 'wrap' : 'nowrap',
      }} >
        {/** Default tabs try to use the same fixed ammount of space and never grow bigger than that **/}
        {Object.entries(groupedTabs).map(([name, group]) => (
          <TabDiv
            active={group.some(t => t.id === active_tab_id)}
            stacked={stacked}
            max_tab_width={max_tab_width}
            style={{maxWidth: '100%'}}
            key={name}
          >
            <TabHeader
              group={group}
              active_tab_id={active_tab_id}
              highlight_tab_ids={highlight_tab_ids}
              setActiveTabId={setActiveTabId}
            />
          </TabDiv>
        ))}
        {/** Small tabs always use the least ammount of space possible **/}
        {groupedSmallTabs && Object.entries(groupedSmallTabs).map(([name, group]) => (
          <TabHeader
            key={name}
            group={group}
            active_tab_id={active_tab_id}
            highlight_tab_ids={highlight_tab_ids}
            setActiveTabId={setActiveTabId}
            max_tab_width={max_tab_width}
            small
          />
        ))}

        {/** Children are shown after the small tabs and don't have any tab-style applied **/}
        <div style={{
          flex: '0 0 auto',
          minHeight: '2.5em',
          borderBottom: `1px solid var(--content-1)`,
          display: 'flex',
          alignItems: 'center',
        }}>
          {children}
        </div>

        {/** Margin right **/}
        <div style={{flex: `1 0 ${tabs_margin_right}`, borderBottom: `1px solid var(--content-1)`}} />

      </div>
      {/** Tab contents **/}
      <div style={{
        flex: 1,
        minHeight: '0px',
        border: `1px solid var(--content-1)`,
        borderTop: '0px',
        ...containerStyle,
      }}>
        {active_tab?.component && <active_tab.component {...active_tab.props}/>}
      </div>
    </div>
  );
};

export default Tabs;
