import React, {
  useState,
  useEffect,
} from 'react';

const processFetchResponse = u => u.ok ? u.json() : u.text().then(t => Promise.reject(`Unexpected error code ${u.status} on url: ${u.url}: ${t}`));

const PortalDisplay = ({
  subscription,
}) => {
  const [ portal_link, setPortalLink ] = useState();
  useEffect(() => {
    fetch(`/api/billing/clients/${subscription.client_id}/portal_link`, {method: 'POST'})
      .then(processFetchResponse)
      .then(({url}) => setPortalLink(url))
  }, [subscription.client_id])
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
      <div style={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: '1em',
        border: '1px solid black',
        borderBottom: '0px',
      }}>
        {portal_link}
      </div>
      <iframe src={portal_link} style={{
        width: '100%',
        height: '100%',
        flex: 1,
        border: '1px solid black',
      }}/>
    </div>
  );
};

export default PortalDisplay;
