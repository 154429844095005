import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

const default_prices = {
  sms: 0.07,
  whatsapp: 0.07,
  email: 0.01,
  call: 0.35,
};

const MessagingPricingEditor = ({dealer_id}) => {
  const [editable_prices, setEditablePrices] = useState({});
  const [saved_discounts, setSavedDiscounts] = useState({});
  const [loading_discounts, setLoadingDiscounts] = useState(true);

  const fetchDiscounts = useCallback(() => {
    setLoadingDiscounts(true);
    fetch(`/api/mapi/dealers/${dealer_id}?project=billing.credit_discounts`)
      .then(res => res.json())
      .then(dealer => {
        setSavedDiscounts(dealer[0]?.billing.credit_discounts || {});
        setLoadingDiscounts(false);
      });
  }, [dealer_id]);

  useEffect(() => {
    fetchDiscounts();
  }, [fetchDiscounts]);

  const saveNewPrice = useCallback(communication_type => {
    const update_patch = [{
      op: 'add',
      path: `/billing/credit_discounts`,
      value: {...saved_discounts, [communication_type]: editable_prices[communication_type] / default_prices[communication_type]},
    }];
    fetch(`/api/mapi/dealers/${dealer_id}`, { method: 'PATCH', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(update_patch) })
      .then(fetchDiscounts)
      .then(() => {
        setEditablePrices({});
      });
  }, [dealer_id, fetchDiscounts, editable_prices, saved_discounts]);

  return (
    <div style={{marginTop: '1em'}}>
      <h4>Precio de la mensajería:</h4>
      {loading_discounts ? (
        <div>Cargando precios...</div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Tipo de mensaje</th>
              <th>Precio unitario (€)</th>
              <th>Nuevo precio unitario (€)</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(default_prices).map(communication_type => (
              <tr key={communication_type}>
                <td>{communication_type}</td>
                <td>{saved_discounts[communication_type] ? parseFloat(default_prices[communication_type] * saved_discounts[communication_type]).toFixed(2) : default_prices[communication_type]}</td>
                <td>
                  <input type="number" placeholder='Punto para decimales' onChange={e => {
                    const value = parseFloat(e.target?.value);
                    setEditablePrices(prev => {
                      return {...prev, [communication_type]: value};
                    });
                  }}/>
                </td>
                <td><button onClick={() => saveNewPrice(communication_type)}>Guardar</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MessagingPricingEditor;
