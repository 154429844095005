import React, {
  useState,
  useEffect,
} from 'react';
import moment from 'moment';

const fetchConferences = async (dealer_id) => fetch(`/api/mappex/services/twilio/dealers/${dealer_id}/voice/current_conferences`)
  .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));

const removeStuckCall = async (dealer_id, call) => fetch(`/api/mappex/services/twilio/dealers/${dealer_id}/voice/conference/${encodeURIComponent(call.redis_key.split(':').slice(2).join(':'))}`, {
  method: 'DELETE',
}).then(res => res.ok || res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)));


const Duration = ({start_date, format}) => {
  const [current_duration, setCurrentDuration] = useState(0);
  useEffect(() => {
    setCurrentDuration(moment().diff(moment(start_date), 'seconds'));
  }, [start_date]);
  return (
    <span style={{
      color: 'var(--content_1)',
      fontFamily: 'monospace',
      fontSize: '0.9rem',
    }}>
      {format === 'seconds' && (
        <span style={{width: '8em'}}>
          {moment.duration(current_duration*1000).as('seconds')}s
        </span>
      )}
      {format === 'full' && current_duration >= 3600 && `${moment.utc(current_duration*1000).format('HH[h] mm[m] ss[s]')}`}
      {format === 'full' && current_duration < 3600 && `${moment.utc(current_duration*1000).format('mm[m] ss[s]')}`}
    </span>
  )
}


const ConferenceList = ({ dealer_id }) => {
  const [ conferences, setConferences ] = useState([])
  useEffect(() => {
    if (dealer_id) fetchConferences(dealer_id).then(setConferences);
  }, [dealer_id]);
  return (
    <div style={{width: '100%'}}>
      <button onClick={() => fetchConferences(dealer_id).then(setConferences)}>Refrescar</button>
      <div style={{
        display: 'grid',
        width: '100%',
        gap: '1em',
        gridTemplateColumns: 'repeat(auto-fit, minmax(30em, 1fr))',
      }}>
        {conferences
          .map(call => Object.assign({}, call, { start_time: (
            call?.participants?.find(p => p.type === 'user')?.entered_at ||
            call?.invitations?.[0]?.started_at
          )}))
          .sort((a1,a2) => a1.start_time - a2.start_time)
          .map(call => {
            return (
              <div style={{
                border: '1px solid var(--content-1)',
                borderRadius: '3px',
                padding: '1em',
                minWidth: '0px',
                display: 'flex',
              }}>
                <div style={{flex: 1}}>
                  <div>Nombre cliente: {call.client_name}</div>
                  <div>Núm cliente: {call.client_number}</div>
                  <div>Núm dealer: {call.dealer_number}</div>
                  <div>Estado: {call.status}</div>
                  <div>Tipo: {call.gsm_uuid ? 'Walcu Phone' : 'VoIP'}</div>
                  <div>Duración: <Duration start_date={call.start_time} format={'seconds'} /></div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <button onClick={() => removeStuckCall(dealer_id, call)
                    .then(() => fetchConferences(dealer_id))
                    .then(setConferences)
                  }>Quitar llamada</button>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default ConferenceList;
