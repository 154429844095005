import React, {
  useEffect,
  useState,
  useMemo,
}                           from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import {
  faCaretUp,
  faCaretDown,
}                           from '@fortawesome/free-solid-svg-icons';

const fetchQueueMetrics = () => {
  const q = {page: 1, page_size: 500};
  return fetch(`/api/mappex/services/rabbitmq/queue_metrics?${new URLSearchParams(q).toString()}`)
    .then(res => res.ok ? (
      res.json()
    ) : (
      res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
    ));
}

const getObjectPath = (obj, path) => path.split('.').reduce((p, c) => p && p[c] || null, obj);
const renderFloat2Decimals = f => f?.toFixed(2).replace(/\.0+$/,'');

const getHeaders = (show_more) => ([{
  label: 'Nombre',
  width: '10%',
  sort_key: 'name',
  type: 'string',
  renderCell: s => s.name,
}, {
  label: 'Consumers',
  width: '6%',
  sort_key: 'consumers',
  type: 'number',
  renderCell: s => {
    const is_zero = s.consumers === 0;
    return <span style={{fontWeight: is_zero ? 'bold' : 'inherit', color: is_zero ? 'red' : 'black'}}>{s.consumers}</span>;
  },
}, {
  label: 'Ready',
  width: '5%',
  sort_key: 'messages_ready',
  type: 'number',
  renderCell: s => s.messages_ready,
  only_show_more: true,
}, {
  label: 'Unacked',
  width: '5%',
  sort_key: 'messages_unacknowledged',
  type: 'number',
  renderCell: s => s.messages_unacknowledged,
  only_show_more: true,
}, {
  label: 'Total',
  width: '5%',
  sort_key: 'messages',
  type: 'number',
  renderCell: s => {
    const ready = s.messages;
    const color = ready > 10000 ? 'red' : ready > 200 ? 'orangered' : 'black';
    return <span style={{fontWeight: ready > 200 ? 'bold' : 'inherit', color: color }}>{ready}</span>;
  },
}, {
  label: 'Publicados /s',
  width: '5%',
  sort_key: 'message_stats.publish_details.rate',
  type: 'number',
  renderCell: s => renderFloat2Decimals(s.message_stats?.publish_details?.rate || 0),
}, {
  label: 'Consumidos /s',
  width: '5%',
  sort_key: 'message_stats.ack_details.rate',
  type: 'number',
  renderCell: s => renderFloat2Decimals(s.message_stats?.ack_details?.rate || 0),
}, {
  label: 'Diferencia',
  width: '5%',
  sort_key: 'difference',
  type: 'number',
  renderCell: s => renderFloat2Decimals(s.difference),
}, {
  label: 'Tiempo estimado',
  width: '10%',
  sort_key: 'estimated_time',
  type: 'number',
  renderCell: s => {
    if (s.estimated_time === Infinity) return s.estimated_time;
    const date = new Date(1000 * (Math.floor(s.estimated_time || 0)));
    return `${date.getUTCHours() > 0 ? `${date.getUTCHours()}h` : ''} ${date.getUTCMinutes() > 0 ? `${date.getUTCMinutes()}m` : ''} ${date.getUTCSeconds() > 0 ? `${date.getUTCSeconds()}s` : ''}`;
  },
}, {
  label: 'Memoria',
  width: '5%',
  sort_key: 'memory',
  type: 'number',
  renderCell: s => renderFloat2Decimals(s.memory / 1024),
  only_show_more: true,
}].filter(({only_show_more}) => !only_show_more || show_more));

const SortIndicator = ({direction}) => (
  <span style={{marginLeft: '0.3em'}}>
    <FontAwesomeIcon icon={direction === 1 ? faCaretUp : faCaretDown}/>
  </span>
)

const sortOrder = (a, b, property, type) => {
  switch (type) {
    case 'number':
      return getObjectPath(a, property) - getObjectPath(b, property);
    case 'string':
      return getObjectPath(a, property).localeCompare(getObjectPath(b, property));
  }
}

const QueuesMetricsTable = ({
  queues_metrics,
  sort_key: props_sort_key,
  setSortKey,
  sort_direction,
  setSortDirection,
  show_more = false,
}) => {
  const headers = useMemo(() => getHeaders(show_more), [show_more]);
  return (
    <div style={{overflow: 'auto'}}>
      <table style={{ width: '100%', alignSelf: 'center', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {headers.map(({label, width, sort_key, type}) => (
              <th
                key={label}
                style={{
                  width,
                  cursor: sort_key ? 'pointer' : 'default',
                }}
                onClick={() => { if(sort_key){
                  if(sort_key === props_sort_key.key){
                    setSortDirection(d => -1 * d);
                  } else {
                    setSortKey({key: sort_key, type: type || 'string'});
                  }
                }}}
              >
                {label}
                {(sort_key && props_sort_key.key === sort_key) ? <SortIndicator direction={sort_direction}/> : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.values(queues_metrics)
            .sort((a, b) => sort_direction * sortOrder(a, b, props_sort_key.key, props_sort_key.type))
            .map(s => (
              <tr>
                {headers.map(({label, renderCell}) => (
                  <td key={label} style={{fontSize: '16px', wordBreak: 'break-word'}}>
                    {renderCell(s)}
                  </td>
                ))}
              </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const getDifference = queue =>
  (queue.message_stats?.ack_details?.rate || 0) - (queue.message_stats?.publish_details?.rate || 0);

const getEstimatedTime = queue => (queue.messages || 0) / (getDifference(queue) || 0) || 0;

const addCalculatedMetrics = queues => queues.map(queue => ({
  ...queue,
  difference: getDifference(queue),
  estimated_time: getEstimatedTime(queue) >= 0 ? getEstimatedTime(queue) : Infinity,
}));

const non_critic_queues = new Set(['task_queue', 'failed-works', 'callback_queue', 'wait', 'updateStock', 'cronjobupdate']);
const isCritic = queue => !non_critic_queues.has(queue.name) && (queue.messages > 1000 || (queue.messages > 0 && (queue.consumers === 0 || queue.estimated_time === Infinity)));

const RabbitMQ = () => {
  document.title = 'Estado del sistema - Walcu Penguin';
  const [ metrics, setMetrics ] = useState([]);
  const [ polling_time, setPollingTime ] = useState(0);
  useEffect(() => {
    const interval_id = setInterval(() => {
      fetchQueueMetrics().then(({items}) => setMetrics(addCalculatedMetrics(items)));
      setPollingTime(500);
    }, polling_time);
    return () => clearInterval(interval_id);
  }, [polling_time]);


  const [ name_filter, setNameFilter ] = useState('');
  const [ show_more, setShowMore ] = useState(false);
  const [ sort_key, setSortKey ] = useState({key: 'messages', type: 'number'});
  const [ sort_direction, setSortDirection ] = useState(-1);

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1em', gap: '0.5em'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.5em'}}>
          Nombre:
          <input type='text' value={name_filter} onChange={e => setNameFilter(e.target.value)}/>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '0.3em',
            alignItems: 'center',
            backgroundColor: 'var(--background-light-2)',
            border: '1px solid var(--content-1)',
            borderRadius: '3px',
            cursor: 'pointer',
            padding: '0.5em',
          }}
          onClick={() => setShowMore(act => !act)}
        >
          <input type='checkbox' readOnly checked={show_more}/>
          Mostrar mas
        </div>
      </div>
      <h2 style={{marginLeft: '0.25em'}}>Colas críticas</h2>
      <QueuesMetricsTable
        queues_metrics={metrics.filter(isCritic)}
        sort_key={sort_key}
        setSortKey={setSortKey}
        sort_direction={sort_direction}
        setSortDirection={setSortDirection}
        show_more={show_more}
      />
      <h2 style={{marginTop: '1em', marginLeft: '0.25em'}}>Todas</h2>
      <QueuesMetricsTable
        queues_metrics={metrics.filter(({name}) => new RegExp(name_filter, 'i').test(name))}
        sort_key={sort_key}
        setSortKey={setSortKey}
        sort_direction={sort_direction}
        setSortDirection={setSortDirection}
        show_more={show_more}
      />
    </div>
  );
};

export default RabbitMQ;
