import styled from 'styled-components';

const StyledButton = styled.button`
  border: 1px solid var(--content-1);
  padding: 0em 0.6em;
  background-color: white;
  border-radius: 3px;
  color: var(--content-4);

  &:hover{
    background-color: var(--accent-1);
  }

  &:disabled{
    background-color: var(--content-1);
  }
`;

export default StyledButton;
