import React, {
  useState,
} from 'react';
import AddressSelector from '../AddressSelector';
import BundleSelector  from '../BundleSelector';
import Async           from 'react-select/async';

const sortDealers = dealers => dealers
  .sort((d1, d2) => d1.friendly_name.localeCompare(d2.friendly_name));

const loadDealers = str => {
  const q = {active: true, friendly_name: {$regex: str, $options: 'i'}};
  return fetch(`/api/mapi/dealers?q=${encodeURIComponent(JSON.stringify(q))}`)
    .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
    .then(res => sortDealers(res).map(d => ({label: d.friendly_name, value: d._id})));
};

const loadNumbers = dealer_id => str => {
  if(!dealer_id) return Promise.resolve([]);
  const q = {$or: [{number: {$regex: str}}, {label: {$regex: str, $options: 'i'}}]};
  return fetch(`/api/mapi/dealers/${dealer_id}/phones?q=${encodeURIComponent(JSON.stringify(q))}`)
    .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
    .then(res => res.map(d => ({label: `${d.label} (${d.number})`, value: d.number})));
};


const PhoneMover = ({ dealer_id }) => {
  const [ dealer, setDealer ] = useState();
  const [ number, setNumber ] = useState();
  const [ address, setAddress ] = useState();
  const [ bundle, setBundle ] = useState();
  const moveNumber = () => fetch(`/api/move_phone`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ to_dealer_id: dealer_id, from_dealer_id: dealer, number, address, bundle }),
  })
    .then(r => r.ok && r.json() || r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
    .then(() => alert('Número movido con éxito.'))
    .catch(err => alert('Error moviendo el número: '.concat(err.toString())))
  return (
    <div>
      <div style={{
        display: 'grid',
        gridGap: '0.5em 1em',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
      }}>
        <span>Cuenta origen</span>
        <Async
          styles={{
            container: base => ({...base, width: '20em'}),
          }}
          onChange={e => (setDealer(e.value), setNumber(null))}
          cacheOptions
          loadOptions={loadDealers}
          defaultOptions
        />
        <span>Número</span>
        <Async
          styles={{
            container: base => ({...base, width: '20em'}),
          }}
          key={dealer}
          onChange={e => setNumber(e.value)}
          cacheOptions={dealer}
          loadOptions={loadNumbers(dealer)}
          defaultOptions
        />
        <span>Dirección</span>
        <AddressSelector dealer_id={dealer_id} onChange={setAddress}/>
        <span>Paquete regulatorio</span>
        <BundleSelector dealer_id={dealer_id} onChange={setBundle}/>
      </div>
      <div><b> Mover el número eliminará permanentemente toda configuración asociada con el mismo en la cuenta de origen </b></div>
      <button
        onClick={moveNumber}
        disabled={!number || !address || !bundle || !dealer}
      >
        Mover
      </button>
    </div>
  );
};

export default PhoneMover;
