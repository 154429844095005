import React, {
  useState,
  useEffect,
  useMemo,
}                     from 'react';
import Select         from 'react-select';
import LoginAs        from './components/LoginAs';
import AppraisalsView from './components/AppraisalsView';
import {
  useQueryParam,
  StringParam,
}                     from 'use-query-params';

const sortTasatorUsers = dealers => dealers
  .sort((d1, d2) => (d1.friendly_name || d1.domain || '').localeCompare(d2.friendly_name || d2.domain || ''));

const loadAllTasatorUsers = async () => {
  const q = {subscribed: true};
  const users = await fetch(`/api/carwatch/users?q=${encodeURIComponent(JSON.stringify(q))}`)
    .then(res => res.ok ? (
      res.json()
    ) : (
      res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`))
    ));
  return users;
};

const hasPortable = user => user?.tasator_config && user?.portable_enabled;

const Tasator = ({ user }) => {
  document.title = 'Tasator - Walcu Penguin';
  const [ tasator_users, setTasatorUsers ] = useState([]);
  const [ selected_user, setSelectedUser ] = useQueryParam('user_id', StringParam);
  const [ selected_has_portable, setSelectedHasPortable ] = useState(false);

  useEffect(() => {
    loadAllTasatorUsers().then(d => setTasatorUsers(sortTasatorUsers(d)));
  }, []);

  useEffect(() => {
    const selected_full_user = tasator_users.find(u => u._id == selected_user);
    setSelectedHasPortable(hasPortable(selected_full_user));
  }, [ tasator_users, selected_user ]);

  const user_options = useMemo(() => tasator_users.map(d => ({
    label: `${d.tasator_username || d.tasator_config.username || d.email} (${d._id}) ${hasPortable(d) ? '(Tiene iframe)' : ''}`,
    value: d._id,
  })),[tasator_users]);

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
  }}>
    <div style={{
      margin: '1em',
      display: 'flex',
      alignItems: 'center',
    }}>
      <Select
        styles={{
          container: base => ({...base, flex: 0.45}),
        }}
        isClearable
        onChange={e => setSelectedUser(e ? e.value : null)}
        options={user_options}
        value={user_options.find(d => d.value === selected_user)}
      />
    </div>
    {user.permissions.includes('LoginAs')&& selected_user && selected_has_portable && (
      <div style={{margin: '0em 1em'}}>
        <LoginAs user_id={selected_user}/>
      </div>
    )}
    {selected_user && (
      <AppraisalsView
        user_id={selected_user}
      />
    )}
  </div>
};

export default Tasator;
