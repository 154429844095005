import React, {
  useState,
  useEffect,
  useMemo,
}             from 'react';
import Select from 'react-select';
const UserGroupForm = ({
  user_group,
  users,
  dealers,
  close,
  saveUserGroup,
}) => {

  const [name, setName] = useState();
  const [members, setMembers] = useState();
  const [can_view_all, setCanViewAll] = useState();
  const [group_dealers, setGroupDealers] = useState();
  const [blacklisted_dealers, setBlacklistedDealers] = useState();
  const [country_codes, setCountryCodes] = useState();

  useEffect(() => {
    setName(user_group?.name ?? '');
    setMembers(user_group?.members ?? []);
    setCanViewAll(user_group?.can_view_all ?? false);
    setGroupDealers(user_group?.dealers ?? []);
    setBlacklistedDealers(user_group?.blacklisted_dealers ?? []);
    setCountryCodes(user_group?.country_codes ?? []);
  }, [user_group]);

  const user_options = useMemo(() => users?.filter(u => u.active)?.map(user => ({
    value: user._id,
    label: `${[user.name, user.surname].filter(Boolean).join(' ')} (${user.email})`,
  })), [users]);
  const dealer_options = useMemo(() => dealers?.map(dealer => ({
    value: dealer._id,
    label: dealer.friendly_name || dealer.domain,
  })), [dealers]);
  const country_codes_options = useMemo(() =>
    Array.from(new Set(dealers.map(d => d.country_code)))
    .map(value => ({label: value, value})),
    [dealers],
  );

  const selected_members = useMemo(() => members?.map(m => user_options.find(uo => uo.value === m)), [members, user_options]);
  const selected_dealers = useMemo(() => group_dealers?.map(dealer_id => dealer_options.find(dealer_option => dealer_option.value === dealer_id)), [group_dealers, dealer_options]);
  const selected_blacklisted_dealers = useMemo(() => blacklisted_dealers?.map(dealer_id => dealer_options.find(dealer_option => dealer_option.value === dealer_id)), [blacklisted_dealers, dealer_options]);
  const selected_country_codes = useMemo(() => country_codes?.map(cc => country_codes_options.find(cco => cco.value === cc)), [country_codes, country_codes_options]);

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em', overflow: 'visible'}}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '1em',
        alignItems: 'center',
      }}>
        <div>Nombre</div>
        <input
          type='text'
          value={name}
          onChange={ev => setName(ev.target.value)}
          style={{border: '1px solid lightgray', borderRadius: '0.2em'}}
        />
        <div>Miembros</div>
        <Select
          isMulti
          portal
          options={user_options}
          value={selected_members}
          onChange={selected_options => setMembers(selected_options?.map(opt => opt.value) || [])}
          isDisabled={!users}
          isLoading={!users}
        />
        <div>Visibilidad total</div>
        <div style={{display: 'flex', gap: '0.5em', alignItems: 'center'}}>
          <label className='switch' style={{marginRight: '1em'}}>
            <input
              className='slider-round'
              type='checkbox'
              checked={can_view_all}
              onChange={() => setCanViewAll(cva => !cva)}
            />
          </label>
          <label>Los miembros de este grupo podrán ver a todos los dealers</label>
        </div>
        {can_view_all || (<>
          <div>Dealers visibles:</div>
          <Select
            isMulti
            portal
            options={dealer_options}
            value={selected_dealers}
            onChange={selected_options => setGroupDealers(selected_options?.map(opt => opt.value) || [])}
            isDisabled={!dealers}
            isLoading={!dealers}
          />
          <div>Paises visibles:</div>
          <Select
            isMulti
            options={country_codes_options}
            value={selected_country_codes}
            onChange={selected_options => setCountryCodes(selected_options?.map(opt => opt.value) || [])}
            isDisabled={!country_codes}
            isLoading={!country_codes}
          />
          <div>Dealers blacklisteados:</div>
          <Select
            isMulti
            portal
            options={dealer_options}
            value={selected_blacklisted_dealers}
            onChange={selected_options => setBlacklistedDealers(selected_options?.map(opt => opt.value) || [])}
            isDisabled={!dealers}
            isLoading={!dealers}
          />
        </>)}
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2em'}}>
        <button
          onClick={() => {
            saveUserGroup({
              name,
              members,
              can_view_all,
              dealers: group_dealers,
              blacklisted_dealers,
              country_codes,
              _id: user_group?._id,
            })
          }}
        >
          Guardar
        </button>
        <button
          style={{background: '#FFABAB'}}
          onClick={close}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default UserGroupForm;
