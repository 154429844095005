import React, {
  useState,
  useEffect,
}                             from 'react';
import NavBar                 from './components/NavBar.js';
import './App.css';
import CustomerSuccess        from './screens/CustomerSuccess';
import Home                   from './screens/Home';
import RegisterRequests       from './screens/RegisterRequests';
import Kpis                   from './screens/Kpis';
import Admin                  from './screens/Admin';
import Billing                from './screens/Billing';
import Library                from './screens/Library';
import Tasator                from './screens/Tasator';
import AuditEvents            from './screens/AuditEvents';
import Client                 from 'webauthn/client';
import { QueryParamProvider } from 'use-query-params';
import RabbitMQ               from './screens/RabbitMQ';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
}                             from "react-router-dom";

const client = new Client({ pathPrefix: '/webauthn' });

const webAuthNLogin = async (email) => {
  const res = await client.login({email});
  if(res && res.status === 'ok') return Promise.resolve();
  return Promise.reject();
};

const buttonStyle = {
  padding: '0.5em',
  marginTop: '1em',
};

const user_routes = {
  home: { label: 'Home', Component: Home},
  kpis: { label: 'KPIs', Component: Kpis, required_permissions:['CustomerSuccess']},
  customer_success: { label: 'Customer Success', Component: CustomerSuccess, required_permissions: ['CustomerSuccess']},
  register_requests: { label: 'Peticiones de registro', Component: RegisterRequests, required_permissions: ['RegisterRequests']},
  admin: { label: 'Admin', Component: Admin, required_permissions: ['Master&Commander']},
  billing: { label: 'Facturación', Component: Billing, required_permissions: ['Billing']},
  rabbitmq: { label: 'Estado del sistema', Component: RabbitMQ, required_permissions: ['SystemStatus']},
  tasator: { label: 'Tasator', Component: Tasator, required_permissions: ['Tasator']},
  library: { label: 'Library', Component: Library, required_permissions: ['Library']},
  audit_avents: { label: 'Audit events', Component: AuditEvents, required_permissions: ['Master&Commander']},
};

const LogInForm = ({
  onLogin,
}) => {
  const [ email, setEmail ] = useState('');
  return (
    <form style={{
      display: 'flex',
      widht: '100vw',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: '#fdf6e3',
    }}
      method='POST' action='/login'>
      <span style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridGap: '1em 1em',
        border: '1px solid black',
        padding: '0.5em',
        borderRadius: '3px',
      }}>
        <span>Email:</span>
        <input
          onChange={e => setEmail(e.target.value)}
          value={email}
          name='username'
          type='text'
        />
        <span>Password:</span>
        <input name='password' type='password'/>
      </span>
      <input style={buttonStyle} type='submit' value='Entrar'/>
      <button
        style={buttonStyle}
        disabled={!email}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          webAuthNLogin(email).then(onLogin)
        }}
      >
        Entrar con llave física
      </button>
    </form>
  );
}

const TwoFactor = () => {
  const [ code, setCode ] = useState('');
  return (
    <form
      style={{
        display: 'flex',
        widht: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#fdf6e3',
      }}
      method='POST' action='/2fa_login'
    >
      <span style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1em 1em',
        border: '1px solid black',
        alignItems: 'center',
        padding: '0.5em',
        borderRadius: '3px',
      }}>
        <span>Se ha enviado un código de verificación por SMS a tu teléfono</span>
        <input
          onChange={e => setCode(e.target.value)}
          value={code}
          name='code'
          type='text'
          autoFocus
        />
      </span>
      <input style={buttonStyle} type='submit' value="Continuar"/>
    </form>
  );
}

const App = () => {
  const [ user, setUser ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  useEffect(() => {
    loading && fetch('/api/user')
      .then(res => res.ok ? res.json() : res.text().then(t => Promise.reject(`Unexpected status ${res.status} at ${res.url}: ${t}`)))
      .then(user => {setUser(user); setLoading(false)})
      .catch(() => setLoading(false))
  }, [loading]);
  if(loading){
    return 'Cargando...';
  } else {
    return (
      <Router>
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Route path="/login" exact render={() => <LogInForm onLogin={() => setLoading(true)}/>}/>
            <Route path="/2fa" exact render={() => <TwoFactor/>}/>
            {user && <NavBar routes={user_routes} user={user}/>}
            <div style={{flex: 1, overflow: 'auto'}}>
              <Switch>
                {user && Object.entries(user_routes).map(([key, {Component}]) => (
                  <Route key={key} path={`/app/${key}`} exact render={() => (
                    <Component webauthn_client={client} user={user}/>
                  )}/>
                ))}
                <Redirect from={'/app'} to={user ? '/app/home' : '/login' }/>
                <Redirect exact from={'/'} to={user ? '/app/home' : '/login' }/>
              </Switch>
            </div>
          </QueryParamProvider>
        </div>
      </Router>
    );
  }
};

export default App;
